const colors = require('@/utils/colors.js');

const overlays = [
    {
        name: 'profile-select',
        height: 80,
        backdropColor: colors.charcoal,
        hideNav: true
    },
    {
        name: 'profile-details',
        height: 50,
        backdropColor: colors.charcoal,
        hideNav: true
    },
    {
        name: 'enter-pin',
        height: 80,
        backdropColor: colors.charcoal,
        hideNav: true
    },
    {
        name: 'profiles-list',
        height: 80,
        canClose: true,
        hideNav: false
    }
]

module.exports = overlays;