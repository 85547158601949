var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full h-full bg-charcoal-opacity z-40 fixed flex flex-col justify-end"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-center justify-between w-full h-2/3 pb-24"
        },
        [
          _c(
            "carousel",
            {
              ref: "carousel",
              staticClass: "w-full",
              attrs: {
                "per-page": 1,
                autoplay: _vm.autoplay,
                "autoplay-timeout": 10000,
                paginationEnabled: false
              },
              on: {
                "page-change": function($event) {
                  _vm.currentSlide = $event
                }
              }
            },
            _vm._l(_vm.items, function(item, index) {
              return _c("slide", { key: index }, [
                _vm.mediaType === "pictures"
                  ? _c("img", {
                      staticClass: "px-10 object-cover w-full h-auto",
                      attrs: { src: item.source }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.mediaType === "videos"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "players text-3xl text-white w-full h-full flex justify-center"
                        },
                        [
                          _c("span", {
                            staticClass: "h-full absolute w-full z-10"
                          }),
                          _vm._v(" "),
                          item.provider === "YOUTUBE" &&
                          _vm.currentSlide === index
                            ? _c("LazyYoutube", {
                                ref: "lazyVideo",
                                refInFor: true,
                                attrs: {
                                  src: item.url,
                                  autoplay: true,
                                  maxWidth: "100%"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          item.provider === "VIMEO" &&
                          _vm.currentSlide === index
                            ? _c("LazyVimeo", {
                                ref: "lazyVideo",
                                refInFor: true,
                                attrs: {
                                  src: item.url,
                                  autoplay: true,
                                  maxWidth: "100%"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          item.provider === "DREAMBROKER" &&
                          _vm.currentSlide === index
                            ? _c("iframe", {
                                staticClass: "w-full",
                                attrs: {
                                  src: item.url + "?autoplay=true",
                                  allow: "autoplay"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            }),
            1
          ),
          _vm._v(" "),
          _vm.mediaType === "videos"
            ? _c("video-controls", {
                on: {
                  playVideo: function($event) {
                    return _vm.handleVideoEvent("playVideo")
                  },
                  pauseVideo: function($event) {
                    return _vm.handleVideoEvent("pauseVideo")
                  },
                  resetView: function($event) {
                    return _vm.handleVideoEvent("resetView")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm.canArchiveAdd
                ? _c("button-el", {
                    staticClass: "shadow-xsm mb-10",
                    attrs: {
                      text: "gallery.addToMy." + _vm.mediaType,
                      icon: "cloud-download",
                      backgroundColor: "success"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.archiveAdd($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.canArchiveRemove
                ? _c("button-el", {
                    staticClass: "shadow-xsm mb-10",
                    attrs: {
                      text: "gallery.removeFromMy." + _vm.mediaType,
                      icon: "trash-alt",
                      backgroundColor: "error"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.archiveRemove($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("carousel-controls", {
            attrs: {
              "total-slides": _vm.items.length,
              "current-slide": _vm.currentSlide + 1,
              "media-type": _vm.mediaType,
              autoplay: _vm.autoplay
            },
            on: {
              "autoplay-toggled": function($event) {
                _vm.autoplay = !_vm.autoplay
              },
              previous: function($event) {
                return _vm.goToSlide("previous")
              },
              next: function($event) {
                return _vm.goToSlide("next")
              },
              close: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }