
<template>
  <div class="list grid-cols-1 items">

    <div v-if="!sortByDay" class="flex flex-col justify-center">
      <card-advanced v-for="(item, index) in items"
          :key="index"
          :cardType="activeContentView.cardType"
          :title="item.title"
          :image="item.coverImage.source"
          :gradient="true"
          :meta="{ icon: item.meta.icon, text: item.meta.text }"
          class="shadow-m"
          @click.native="$emit('item-selected', item)"
      />
    </div>

    <div v-else>
      <div class="itemDay" v-for="(itemDay, index) in Object.values(dayItems)" :key="index">
        <div class="dayLabelWrapper sticky z-20">
          <span :class="`bg-${moduleColor}`" 
            class="dayLabel p-4 text-3xl text-white left-0 rounded-r-lg">
            {{formatDayLabel(itemDay.meta.startDate)}}
          </span>
        </div>

        <card-advanced v-for="(item, index) in itemDay"
              :key="index"
              :cardType="activeContentView.cardType"
              :title="item.title"
              :weekday="getWeekday(item)"
              :meta="{ icon: item.meta.icon, text: item.meta.text }"
              :isNow="isItemNow(item)"
              :isCancelled="item.cancelled"
              :image="item.coverImage.source"
              :gradient="true"
              class="shadow-m"
              @click.native="$emit('item-selected', item)"
        />
      </div>
    </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardAdvanced from '@/components/cards/Advanced';
import { capitalize, cloneDeep } from 'lodash';
import { da } from 'date-fns/locale';
import { isItemNow, isItemToday, getWeekday, formatTime } from '@/utils/helpers.js';
import { format, isToday, isTomorrow, isThisWeek } from 'date-fns';

export default {
  components: {
    CardAdvanced
  },
  props: {
    items: {
      type: Array,
      'default': (() => [])
    },
    dayItems: {
      type: Object,
      'default': (() => {})
    },
    widthPercent: {
      type: Number,
      'default': 100
    },
    sortByDay: {
      type: Boolean,
      'default': false
    }
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    itemDays() {
      return cloneDeep(this.items).reduce((accumulator, current, index) => {
        const uniqueDate = current.startDate.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.startDate,
            showTodayLabel: index === 0 && isItemToday(current.startDate)
          }
        };

        return accumulator;
      }, {});
    },
  },
  methods: {
    formatDayLabel(dateString) {
      const date = new Date(dateString);
      if(isToday(date)) return capitalize(this.$t('global.today'));
      if(isTomorrow(date)) return capitalize(this.$t('global.tomorrow'));
      if(isThisWeek(date, { locale: da})) return capitalize(format(date, 'EEEE', { locale: da}));

      return capitalize(format(date, 'EEEE dd MMMM', { locale: da }));
    },
    getWeekday(item) {
      return getWeekday(item);
    },
    isItemNow(item) {
      return isItemNow(item);
    }
  }
};
</script>

<style lang="scss">
  .items {
    .itemDay {
      right: 0;
      position: relative;
      display: flex;
      flex-direction: column;

      &:first-child .dayLabelWrapper .dayLabel {
        margin-top: 25px;
      }

      .dayLabelWrapper {
        top: 180px;
        padding: 25px 0;
        height: fit-content;

        .dayLabel {
          position: absolute;
          padding: 25px 60px;
          border-radius: 0px 12px 12px 0px;
          margin-left: -2px;
          width: fit-content;
          margin-top: 75px;
        }
      }
    }
  }

</style>
