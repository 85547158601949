<template>
    <div class="select-el flex flex-col absolute bg-white shadow-lg">
        <div v-for="(option, index) in options"
            :key="index"
            class="option inline-flex items-center px-20 py-14 border border-b-2 border-gray-100"
            :style="{ display: option.name === selectedOption.name && hideSelectedOption ? 'none' : '' }"
            @click="$emit('option-selected', index)"
        >
            <fa-icon :icon="[ 'fal', 'house-user' ]"
                :color="option.color || '#000'"
                class="text-3xl mr-3"
            />

            <p class="text-bold text-3xl overflow-ellipsis overflow-hidden whitespace-nowrap" :style="{color: `${option.color}`}">
                {{ $t(option.name) }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            'default': () => {
                [{
                    name: '',
                    color: ''
                }]
            }
        },
        selectedOption: {
            type: Object,
            'default': (() => {})
        },
        hideSelectedOption: {
            type: Boolean,
            'default': false
        }
    }
};

</script>

<style lang="scss">
    .select-el {
        max-height: 600px;
        max-width: 476px;
        overflow-y: scroll;
    }
</style>