var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mediaIcons absolute -top-28 right-10 h-20 flex justify-end w-full"
    },
    _vm._l(_vm.icons, function(icon, index) {
      var _obj, _obj$1
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "rounded rounded-full bg-white w-20 h-20 ml-6 flex justify-center items-center relative",
          class:
            ((_obj = {}),
            (_obj["bg-" + _vm.moduleColor] = _vm.mediaType === icon.name),
            _obj)
        },
        [
          _c("fa-icon", {
            staticClass: "text-4xl",
            class:
              ((_obj$1 = { "text-white": _vm.mediaType === icon.name }),
              (_obj$1["text-" + _vm.moduleColor] = _vm.mediaType !== icon.name),
              _obj$1),
            attrs: { icon: ["fal", icon.fa] },
            on: {
              click: function($event) {
                return _vm.$emit("media-selected", icon.name)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded rounded-full border border-white border-2 text-white absolute -right-1 -bottom-1 w-8 h-8 flex items-center justify-center text-xl font-bold",
              class: "bg-" + _vm.moduleColor
            },
            [_vm._v("\n          " + _vm._s(icon.amount) + "\n        ")]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }