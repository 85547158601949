import { render, staticRenderFns } from "./ProfileDetails.vue?vue&type=template&id=b631ec52&scoped=true&"
import script from "./ProfileDetails.vue?vue&type=script&lang=js&"
export * from "./ProfileDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b631ec52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/carolinethuelarsen/Documents/development/ibg-screen-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b631ec52')) {
      api.createRecord('b631ec52', component.options)
    } else {
      api.reload('b631ec52', component.options)
    }
    module.hot.accept("./ProfileDetails.vue?vue&type=template&id=b631ec52&scoped=true&", function () {
      api.rerender('b631ec52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/views/overlays/ProfileDetails.vue"
export default component.exports