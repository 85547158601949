var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contentFilter grid grid-cols-3 px-10 gap-14" },
    [
      _c("card-basic", {
        staticClass: "shadow-xsm",
        attrs: { title: "global.showAll", active: _vm.activeItemIndex === -1 },
        nativeOn: {
          click: function($event) {
            return _vm.$emit("filter-selected", -1)
          }
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, index) {
        return _c("card-basic", {
          key: index,
          staticClass: "shadow-xsm",
          attrs: {
            title: _vm.getItemTitle(item),
            imageUrl: _vm.getImageUrl(item),
            active: _vm.activeItemIndex === index
          },
          nativeOn: {
            click: function($event) {
              return _vm.$emit("filter-selected", index)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }