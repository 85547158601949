export default {
    bind(el, binding, vnode, excludes) {
        el.event = function (event) {
            if (!(el == event.target || el.contains(event.target) 
            || (binding.value.excludeString
            && [...document.body.querySelectorAll(binding.value.excludeString)].includes(event.target)))) 
            {
                binding.value.handler(event);
            }
        };
        document.body.addEventListener('click', el.event)
    },
    unbind(el) {
        document.body.removeEventListener('click', el.event)
    }
};