import { apolloClient } from '../../utils/apollo';
import store from '@/store';
import { cloneDeep } from 'lodash';
import * as Sentry from "@sentry/vue";

const state = {
  all: []
};

const mutations = {
  SET_DEFAULT_ACTIVITIES(state, activities) {
    state.all = activities;
  }
};

const actions = {
  async getActivities({commit}) {
    const departmentIds = store.getters['settings/departments'].map(department => department.id);
    const dateNow = new Date();
    const future90days = dateNow.setDate(dateNow.getDate() + 90);
    const dateEnd = new Date(future90days).toISOString().split('T')[0];

    await apolloClient
      .query({
        query: require('@/graphql/queries/activityRange').default,
        variables: {
          filter: {
            departments: departmentIds
          },
          dateFrom: new Date().toISOString().split('T')[0],
          dateTo: dateEnd,
          timezone: "Europe/Copenhagen"
        },
      })
      .then(response => {
        commit('SET_DEFAULT_ACTIVITIES', response.data.activityRange);
      })
      .catch(error => {
        console.log('Error getting activities', error);
      })
  },
  async activitySignupScreen({commit}, { subscription, profile, opt }) {
    Sentry.captureMessage("Something went wrong");

    const mutation = opt === 'in' ? 'activitySignupScreen' : 'activityCancelSignupScreen';

    await apolloClient
      .mutate({
        mutation: require(`@/graphql/mutations/${mutation}`).default,
        variables: {
          subscription,
          profile
        },
      })
      .then(async () => {
        // TODO: Add to sentry
        store.dispatch('general/setPinValidation', { validating: false, validPin: true });
        store.dispatch('activities/updateParticipants', subscription.id);
      })
      .catch(error => {
        store.dispatch('general/setPinValidation', { validating: false, validPin: false });
        throw new Error('Error signing up to activity', error);
      });
  },
  async updateParticipants({commit}, activityId) {
    await apolloClient
      .query({
        query: require('@/graphql/queries/activityEntry').default,
        variables: {
          id: activityId
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const participants = response.data.activityEntry.participants;
        
        const allActivities = cloneDeep(state.all);
        const activityIndex = allActivities.findIndex(activity => activity.id === activityId);
        
        if (activityIndex > -1) {
          allActivities[activityIndex].participants = participants;
          commit('SET_DEFAULT_ACTIVITIES', allActivities);
        }
      }).catch(error => {
        console.log('Error: ', error);
      });
  }
};

const getters = {
  all: state => state.all
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
