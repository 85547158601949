var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "galleriesWrapper",
      class: {
        "verticalCenter flex items-center flex-col justify-center items-stretch":
          _vm.verticalCenterGrid || _vm.verticalCenterList
      }
    },
    [
      _vm.shownGalleries.length
        ? _c(
            "div",
            { staticClass: "galleries bg-white" },
            [
              _vm.activeContentView.name === "list"
                ? _c("list-view", {
                    attrs: {
                      items: _vm.shownGalleries,
                      widthPercent: 80,
                      sortByDay: false
                    },
                    on: {
                      "item-selected": function($event) {
                        return _vm.showSingleGallery($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContentView.name === "grid"
                ? _c("grid-view", {
                    attrs: { items: _vm.shownGalleries, itemType: "gallery" },
                    on: {
                      "item-selected": function($event) {
                        return _vm.showSingleGallery($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContentView.name === "filter"
                ? _c("content-filter", {
                    attrs: {
                      items: _vm.categories,
                      activeItemIndex: _vm.selectedCategoryIndex
                    },
                    on: {
                      "filter-selected": function($event) {
                        return _vm.categorySelected($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }