<template>
  <div class="contentFilter grid grid-cols-3 px-10 gap-14">
    <card-basic
          title="global.showAll"
          :active="activeItemIndex === -1"
          class="shadow-xsm"
          @click.native="$emit('filter-selected', -1)"
    />
    <card-basic v-for="(item, index) in items"
          :key="index"
          :title="getItemTitle(item)"
          :imageUrl="getImageUrl(item)"
          :active="activeItemIndex === index"
          class="shadow-xsm"
          @click.native="$emit('filter-selected', index)"
    />
  </div>
</template>
<script>

import { get } from 'lodash';
import { mapGetters } from 'vuex';
import CardBasic from '@/components/cards/Basic';

export default {
  components: {
    CardBasic
  },
  data() {
    return {
      showPrompt: false,
    }
  },
  props: {
    items: {
      type: Array,
      'default'() {
        return [];
      }
    },
    activeItemIndex: {
      type: Number,
      'default': -1
    }
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView'
    }),
  },
  methods: {
    getImageUrl(item) {
      return get(item, 'logo.source', '');
    },
    getItemTitle(item) {
      return item.name || item.title;
    }
  }
};
</script>
<style lang="scss">

</style>
