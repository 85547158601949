<template>
  <div>
    <gallery-modal
          v-if="hasSelectedMediaItem"
          :selectedItemIndex="selectedMediaIndex"
          :media-type="mediaType"
          :items="mediaItems"
          @close="selectedMediaIndex = -1"
    />

    <cover-view v-if="galleries.length" :image-url="gallery.coverImage.source"
          :gradient="false"
          :margin-top="40"
    >
      <div class="galleryContent">

        <media-icons :icons="mediaIcons"
              :media-type="mediaType"
              @media-selected="mediaType = $event" />

        <div class="largeListItemHeader p-20">{{ gallery.title }}</div>

        <div v-if="mediaType === 'pictures'" class="grid grid-cols-2 px-20 gap-3 object-cover">
          <img v-for="(image, index) in gallery.pictures"
              :key="index"
              :src="image.source"
              class="object-cover h-80 w-full"
              @click="selectedMediaIndex = index"
          />
        </div>

        <div v-if="mediaType === 'videos'" class="w-full px-20">
          <div v-for="(video, index) in gallery.videos"
              :key="index"
              class="video w-full mb-3 relative"
              @click="selectedMediaIndex = index"
          >
            <div class="rounded rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center">
              <fa-icon :icon="['fal', 'caret-right']" class="text-5xl" />
            </div>
            <img :src="video.thumbnail" class="w-full h-full object-cover" />
          </div>
        </div>

      </div>
    </cover-view>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import Button from '@/components/generic/Button';
import CoverView from '@/views/generic/CoverView';
import Overlay from '@/views/overlays/Overlay';
import MediaIcons from '@/components/generic/MediaIcons';
import GalleryModal from '@/components/generic/GalleryModal';

export default {
  components: {
    ButtonEl: Button,
    CoverView,
    Overlay,
    MediaIcons,
    GalleryModal
  },
  data() {
    return {
      mediaType: 'pictures',
      selectedMediaIndex: -1
    }
  },
  watch: {
    activeProfile(newValue) {
      if (!isEmpty(newValue)) {
        this.$store.dispatch('profiles/getMyArchive');
      }
    }
  },
  computed: {
    ...mapGetters({
      galleries: 'galleries/all',
      activeProfile: 'profiles/activeProfile',
      myArchive: 'profiles/myArchive'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    gallery() {
      return this.galleries.find(gallery => gallery.id === this.$route.params.id);
    },
    mediaIcons() {
      return [
        {
          name: 'pictures',
          fa: 'image',
          amount: this.gallery.pictures.length
        },
        {
          name: 'videos',
          fa: 'film',
          amount: this.gallery.videos.length
        }
      ];
    },
    mediaItems() {
      return this.mediaType === 'pictures' ? this.gallery.pictures : this.gallery.videos;
    },
    selectedMediaItem() {
      return this.galleries[this.selectedMediaIndex];
    },
    hasSelectedMediaItem() {
      return !isEmpty(this.selectedMediaItem);
    }
  }
};
</script>

<style lang="scss">
  .video {
    height: 495px;
  }
</style>