var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "bg-" + _vm.$route.meta.color }, [
    _vm._v("\n  Booking\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }