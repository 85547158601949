import gql from "graphql-tag";

export default gql`
query activityEntry ($id: ID!) {
    activityEntry (id: $id) {
        ... on SingleActivity {
            participants {
                ... on Employee {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                }
                ... on Resident {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                }
            }
        }
        ... on RepeatingActivity {
            participants {
                ... on Employee {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                }
                ... on Resident {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                }
            }
        }
    }
}`;