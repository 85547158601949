var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-el flex flex-col absolute bg-white shadow-lg" },
    _vm._l(_vm.options, function(option, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "option inline-flex items-center px-20 py-14 border border-b-2 border-gray-100",
          style: {
            display:
              option.name === _vm.selectedOption.name && _vm.hideSelectedOption
                ? "none"
                : ""
          },
          on: {
            click: function($event) {
              return _vm.$emit("option-selected", index)
            }
          }
        },
        [
          _c("fa-icon", {
            staticClass: "text-3xl mr-3",
            attrs: {
              icon: ["fal", "house-user"],
              color: option.color || "#000"
            }
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "text-bold text-3xl overflow-ellipsis overflow-hidden whitespace-nowrap",
              style: { color: "" + option.color }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t(option.name)) + "\n        "
              )
            ]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }