import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas as ffas } from '@fortawesome/free-solid-svg-icons';
import { fas as pfas } from '@fortawesome/pro-solid-svg-icons';
import { fal as pfal } from '@fortawesome/pro-light-svg-icons';
import { far as pfar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import apolloClient from './utils/apollo';
import { ls } from '@/utils/storage';
import VueCarousel from 'vue-carousel';
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import LazyTube from "vue-lazytube";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import IdleVue from 'idle-vue';
// import VueMatomo from 'vue-matomo';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/utils/lang';
import auth from '@/utils/auth';
import StoragePlugin from '@/plugins/storage';
import clickoutside from '@/directives/clickoutside';
import moment from 'moment'

import 'animate.css';
import '@scss/main.scss';

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    maxBreadcrumbs: 50,
    debug: false,
    release: process.env.SENTRY_RELEASE || "ibg-screen-vue@unknown",
    environment: process.env.NODE_ENV || "development",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "ibg-screen.proreact.dk",
          "api.proreact.dk",
          /^\//
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    tracingOrigins: {
      trackComponents: true
    },
    attachProps: true,
    attachStacktrace: true
  });
}

Vue.directive('clickoutside', clickoutside);
Vue.prototype.moment = moment;

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';

Vue.component('fa-icon', FontAwesomeIcon);

library.add(ffas);
library.add(pfas);
library.add(pfal);
library.add(pfar);

Vue.use(StoragePlugin);
Vue.use(VueCarousel);
Vue.use(VueDatePicker);
Vue.use(LazyTube);

const app = new Vue({
  i18n,
  router,
  store,
  apolloClient,
  render: h => h(App)
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutes
  startAtIdle: true
});

// Vue.use(VueMatomo, {
//   host: 'https://aws.proreact.dk/tracking/',
//   siteId: 10,
//   router
// });


const authenticate = async () => {
  const departmentData = ls.get('department_data');

  if (process.env.NODE_ENV === 'development' && departmentData) {
    await auth.get(ls.get('department_data').loginKey);
    return;
  }

  if (auth.isValid()) return true;

  const loginKey = new URLSearchParams(window.location.search).get('loginKey');
  
  if (loginKey) {
    await auth.get(loginKey);
  }
};

authenticate().then(() => {
  app.$mount('#app');
});
