<template>
  <div class="galleriesWrapper" :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }">
    <div v-if="shownGalleries.length" class="galleries bg-white">

      <list-view
          v-if="activeContentView.name === 'list'"
          :items="shownGalleries"
          :widthPercent="80"
          :sortByDay="false"
          @item-selected="showSingleGallery($event)"
      ></list-view>

      <grid-view
          v-if="activeContentView.name === 'grid'"
          :items="shownGalleries"
          itemType="gallery"
          @item-selected="showSingleGallery($event)"
      ></grid-view>

      <content-filter
          v-if="activeContentView.name === 'filter'"
          :items="categories"
          :activeItemIndex="selectedCategoryIndex"
          @filter-selected="categorySelected($event)"
      />

    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { cloneDeep, isEmpty } from 'lodash';
import ListView from '@/components/generic/ListView';
import GridView from '@/components/generic/GridView';
import ContentFilter from '@/components/generic/ContentFilter';

export default {
  components: {
    ListView,
    GridView,
    ContentFilter
  },
  data() {
    return {
      selectedCategoryIndex: -1
    }
  },
  computed: {
    ...mapGetters({
      galleries: 'galleries/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      activeItemFilter: 'general/activeItemFilter'
    }),
    verticalCenterList() {
      return this.activeViewType === 'list' && this.galleries.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.galleries.length < 10) || (this.activeContentView.name === 'filter' && this.categories.length < 10);
    },
    shownGalleries() {
      const galleries = cloneDeep(this.galleries).map(gallery => {
        return {
          ...gallery,
          meta: this.getMeta(gallery)
        }
      });
      if (isEmpty(this.activeItemFilter)) return galleries;

      return galleries.filter(gallery => gallery.category && gallery.category.id === this.activeItemFilter.id);
    },
    categories() {
        const categories = [];

        this.galleries.forEach(gallery => {
          if (gallery.category) {
            categories.push(gallery.category);
          }
        });

        return [ ...new Set(categories) ];
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
  },
  async mounted() {
    await this.$store.dispatch('general/setActiveViewType', 'list');
    await this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[0]);
  },
  methods: {
    showSingleGallery(gallery) {
      this.$router.push(`/galleries/${gallery.id}`);
    },
    categorySelected(index) {
      this.selectedCategoryIndex = index;
      const contentView = this.contentViews.find(filter => filter.name === this.activeViewType);
      this.$store.dispatch('general/setActiveContentView', contentView);
      this.$store.dispatch('general/setActiveItemFilter', this.categories[index]);
    },
    getMeta(gallery) {
      const text = !gallery.videos.length ? this.$t('global.images') : this.$t('global.imagesAndVideos');

      return  {
        icon: 'images',
        text: `${gallery.pictures.length} ${text}`
      }
    }
  }
}

</script>

<style lang="scss">
  .galleriesWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.gridView {
      padding-top: 15px;
    }
  }
</style>