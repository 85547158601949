import gql from "graphql-tag";

export default gql`
query galleries {
    galleries {
        id
        title
        category {
            id
            title
            description
        }
        coverImage {
            id
            description
            blurhash
            source
        }
        pictures {
            id
            description
            blurhash
            source
            favorited
            inMyArchive
        }
        videos {
            id
            provider
            url
            description
            blurhash
            favorited
            thumbnail
        }
    }
}`