<template>
  <div class="w-full h-full bg-charcoal-opacity z-40 fixed flex flex-col justify-end">
    <div class="flex flex-col items-center justify-between w-full h-2/3 pb-24">
      <carousel
          ref="carousel"
          class="w-full"
          :per-page="1"
          :autoplay="autoplay"
          :autoplay-timeout="10000"
          :paginationEnabled="false"
          @page-change="currentSlide = $event"
      >
        <slide v-for="(item, index) in items" :key="index">
          <img
              v-if="mediaType === 'pictures'"
              :src="item.source"
              class="px-10 object-cover w-full h-auto"
          >

          <div v-if="mediaType === 'videos'">
            <div class="players text-3xl text-white w-full h-full flex justify-center">
              <span class="h-full absolute w-full z-10"></span>
              <LazyYoutube
                  ref="lazyVideo"
                  v-if="item.provider === 'YOUTUBE' && currentSlide === index"
                  :src="item.url"
                  :autoplay="true"
                  maxWidth="100%"
              />

              <LazyVimeo
                  ref="lazyVideo"
                  v-if="item.provider === 'VIMEO' && currentSlide === index"
                  :src="item.url"
                  :autoplay="true"
                  maxWidth="100%"
              />

              <iframe
                  class="w-full"
                  v-if="item.provider === 'DREAMBROKER' && currentSlide === index"
                  :src="`${item.url}?autoplay=true`"
                  allow="autoplay"
              ></iframe>
            </div>
          </div>
        </slide>
      </carousel>

      <video-controls
          v-if="mediaType === 'videos'"
          @playVideo="handleVideoEvent('playVideo')"
          @pauseVideo="handleVideoEvent('pauseVideo')"
          @resetView="handleVideoEvent('resetView')"
      />
      
      <div class="flex">
        <button-el
            v-if="canArchiveAdd"
            :text="`gallery.addToMy.${mediaType}`"
            icon="cloud-download"
            backgroundColor="success"
            class="shadow-xsm mb-10"
            @click.native="archiveAdd"
        />

        <button-el
            v-if="canArchiveRemove"
            :text="`gallery.removeFromMy.${mediaType}`"
            icon="trash-alt"
            backgroundColor="error"
            class="shadow-xsm mb-10"
            @click.native="archiveRemove"
        />
      </div>

      <carousel-controls
          :total-slides="items.length"
          :current-slide="currentSlide + 1"
          :media-type="mediaType"
          :autoplay="autoplay"
          @autoplay-toggled="autoplay = !autoplay"
          @previous="goToSlide('previous')"
          @next="goToSlide('next')"
          @close="$emit('close')"
      />
    </div>
  </div>
</template>

<script>

import { Carousel, Slide } from 'vue-carousel';
import CarouselControls from '@/components/generic/CarouselControls';
import VideoControls from '@/components/generic/VideoControls';
import Button from '@/components/generic/Button';
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
import { mapGetters } from 'vuex';
import { get, isEmpty } from 'lodash';

export default {
  components: {
    Carousel,
    Slide,
    CarouselControls,
    VideoControls,
    ButtonEl: Button,
    LazyYoutube,
    LazyVimeo
  },
  data() {
    return {
      slideDirection: '',
      currentSlide: 0,
      autoplay: false,
      opt: ''
    }
  },
  props: {
    items: {
      type: Array,
      'default': (() => [])
    },
    selectedItemIndex: {
      type: Number,
      'default': 0
    },
    mediaType: {
      type: String,
      'default': ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.carousel.goToPage(this.selectedItemIndex);
    });
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'general/pinValidation',
      myArchive: 'profiles/myArchive'
    }),
    profileSelected() {
      return !isEmpty(this.activeProfile);
    },
    canArchiveAdd() {
      if (!isEmpty(this.activeProfile)) {
        return !this.isInArchive;
      }

      return true;
    },
    canArchiveRemove() {
      if (!isEmpty(this.activeProfile)) {
        return this.isInArchive;
      }

      return false;
    },
    isInArchive() {
      const items = get(this, `myArchive[${this.mediaType}]`, []);
      return items.findIndex(resource => resource.id === this.items[this.currentSlide].id) > -1;
    }
  },
  methods: {
    archiveAdd() {
      this.opt = 'in';

      const pinOverlayData = {
        title: this.$t(`gallery.addToMy.${this.mediaType}`),
        titleSuccess: this.$t(`gallery.addedToMy.${this.mediaType}`),
        opt: this.opt
      };

      this.showOverlay(pinOverlayData);
    },
    archiveRemove() {
      this.opt = 'out';

      const pinOverlayData = {
        title: this.$t(`gallery.removeFromMy.${this.mediaType}`),
        titleSuccess: this.$t(`gallery.removedFromMy.${this.mediaType}`),
        opt: this.opt
      };

      this.showOverlay(pinOverlayData);
    },
    showOverlay(pinOverlayData) {
      this.$store.dispatch(
        'general/setActiveOverlay',
        !this.profileSelected ? { name: 'profile-select' } : { name: 'enter-pin', data: pinOverlayData }
      );

      if (!this.profileSelected) this.$store.dispatch('general/setNextActiveOverlay', { name: 'enter-pin', data: pinOverlayData });
    },
    goToSlide(direction) {
      const currentSlide = this.$refs.carousel.currentPage;
      const nextSlide = direction === 'next' ? currentSlide + 1 : currentSlide - 1;
      this.$refs.carousel.goToPage(nextSlide);
      this.currentSlide = nextSlide;
    },
    getSource(item) {
      return `${item.url}?autoplay=1`;
    },
    handleVideoEvent(event) {
      this.$refs.lazyVideo[0][event]();
    }
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validating) return;

      const archiveData = {
        resourceId: this.items[this.currentSlide].id.toString(),
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString()
        },
        opt: this.opt
      };

      this.$store.dispatch('galleries/archiveAddScreen', archiveData);
    }
  },
}

</script>

<style lang="scss">
  .media {
    border: 2px solid red;
  }
  .VueCarousel {
    height: 50%;
  }
  .VueCarousel-slide {
    align-self: center;
  }
  .VueCarousel-inner {
    height: 100% !important;
  }
  .VueCarousel-wrapper {
    height: 100%;
  }
</style>