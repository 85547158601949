import { ls } from '@/utils/storage';
import store from '@/store';
import axios from 'axios';
import { addSeconds } from 'date-fns';

export default {
  isValid() {
    const tokenTTL = ls.get('api_token_ttl', 0);
    const isValid = Date.now() < tokenTTL;

    if(!isValid){
      ls.remove('api_token');
      ls.remove('api_token_ttl');
      ls.remove('department_data');
    }

    return isValid;
  },
  async get(token) {
    const data = (await axios.get(`https://ibg.proreact.dk/api/Departments/${token}/login`)).data;

    if (!data) {
      throw new Error('No data');
    }

    ls.set('api_token', data.id);
    ls.set('api_token_ttl', addSeconds(new Date(data.created), data.ttl).getTime());
    ls.set('department_data', data.department);

    store.dispatch('general/setDepartmentData', data.department);
  }
};
