<template>
  <div>
    <div v-if="itemType === 'activity'" class="grid-view grid grid-cols-3 px-10 gap-14">
      <card-advanced v-for="(item, index) in items"
            :key="index"
            :cardType="activeContentView.cardType"
            :title="item.title"
            :weekday="getWeekday(item)"
            :meta="{ icon: item.meta.icon, text: item.meta.text }"
            :isNow="isItemNow(item)"
            :isCancelled="item.cancelled"
            :image="item.coverImage.source"
            :gradient="true"
            class="shadow-m"
            @click.native="$emit('item-selected', item)"
      />
    </div>

    <div v-else class="grid-view grid grid-cols-3 px-10 gap-14">
      <card-advanced v-for="(item, index) in items"
            :key="index"
            :cardType="activeContentView.cardType"
            :title="item.title"
            :meta="{ icon: item.meta.icon, text: item.meta.text }"
            :image="item.coverImage.source"
            :gradient="true"
            class="shadow-m"
            @click.native="$emit('item-selected', item)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardAdvanced from '@/components/cards/Advanced';
import { isItemNow, getWeekday, formatTime } from '@/utils/helpers.js';

export default {
  components: {
    CardAdvanced
  },
  props: {
    items: {
      type: Array,
      'default': (() => [])
    },
    itemType: {
      type: String,
      'default': 'activity'
    }
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
  },
  methods: {
    getWeekday(item) {
      return getWeekday(item);
    },
    formatTime(item) {
      return formatTime(item);
    },
    isItemNow(item) {
      return isItemNow(item);
    }
  }
};
</script>