<template>
  <div class="module-list z-10 text-center space-x-4 flex flex-wrap justify-center content-center">
    <router-link v-for="(module, index) in modules" 
      :key="index"
      :to="module.path"
      :class="{ [`bg-${module.meta.color}`]: activePath === module.path }"
      active-class="active"
      class="module rounded text-white justify-center items-center flex flex-col"
    >
    
      <fa-icon :icon="module.meta.icon"
        class="text-3xl"
        :class="{ [`text-${module.meta.color}`]: activePath !== module.path }"
      />

      <p class="text-sm mt-1 font-bold"
        v-t="module.name"
        :class="{ [`text-${module.meta.color}`]: activePath !== module.path }"
      />
    </router-link>
  </div>
</template>
<script>

export default {
  name: 'module-list',
  computed: {
    modules() {
      return this.$router.options.routes.filter( route => route.path !== '/' && route?.meta?.hideInModuleList !== true );
    },
    activePath() {
      return this.modules.filter( module => this.$route.path.indexOf(module.path) > -1 )[0]?.path;
    },
  }
};
</script>
<style lang="scss">
  .module-list {
    height: 160px;
  }
  .module {
    width: 90px;
    height: 90px;
  }
</style>
