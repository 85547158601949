<template>
    <transition
      enter-active-class="animate__animated animate__slideInUp"
      leave-active-class="animate__animated animate__slideOutDown"
      :duration="1300"
    >
        <div v-show="show"
            :style="overlayStyles"
            class="overlay flex flex-col w-full fixed bottom-0 left-0 fixed h-full"
            :class="{ 'z-40': activeOverlay.hideNav }"
        >
            <div class="backdrop bg-white" :style="backdropStyles">
                <fa-icon v-if="activeOverlay.canClose"
                        :icon="['fal', 'times']"
                        class="text-5xl h-auto font-bold absolute right-20 mt-20"
                        @click="$emit('closeOverlay')"
                />
                <component :is="activeOverlay.name" @closeOverlay="$emit('closeOverlay')" />
            </div>
        </div>
    </transition>
</template>

<script>
import EnterPin from '@/views/overlays/EnterPin';
import ProfileSelect from '@/views/overlays/ProfileSelect';
import ProfileDetails from '@/views/overlays/ProfileDetails';
import ProfilesList from '@/views/overlays/ProfilesList';
import { mapGetters } from 'vuex';
import { hexToRgbA } from '@/utils/helpers.js';

export default {
    components: {
        EnterPin,
        ProfileSelect,
        ProfileDetails,
        ProfilesList
    },
    props: {
        show: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapGetters({
            activeOverlay: 'general/activeOverlay' 
        }),
        overlayStyles() {
            return { backgroundColor: hexToRgbA(this.activeOverlay.backdropColor, 0.9) || 'transparent' }
        },
        backdropStyles() {
            const height = this.activeOverlay.height || 100;
            return { height: `${height}vh`, marginTop: `${ 100 - height }vh`};
        }
    }
}
</script>

<style lang="scss">
    .overlay {
        animation-duration: 1.3s;

        .backdrop {
            border-radius: 25px 25px 0 0;
        }
    }
</style>