import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from "apollo-link-context";
import { ls } from '@/utils/storage';

// https://api.ibg.test/ibg/graphql
// https://api.proreact.dk/ibg/graphql

// TODO: uri from .env
const httpLink = createHttpLink({
    uri: "https://api.proreact.dk/ibg/graphql"
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: 'gkqJGBmArQaePgw1inHht0aIHuXWV3hgdqiBOX2P7NTsbGCh5C1hvOOadQHk4YyO'
        }
    };
});

// r/HIYFMxsvVaMABCT432aIsokGue3iyUvrvoi1GLX94=
// naK4CYpJMASOUbJQx2NZF8FjIEWHy2kTax4Ds6kAR4PvjYfPUhHG9FhosU06TZ9U -> employee
// gkqJGBmArQaePgw1inHht0aIHuXWV3hgdqiBOX2P7NTsbGCh5C1hvOOadQHk4YyO -> department prod
// XrOx2ffUdlJpG2JtkwLyOmo9DDzeKQy0e4DfAeVK4DzdkPcdjviSCYsiuVL3oVl1 -> department local
// 004STjh7E0Va6JKY5CziS0ilb0bobnrApWePHCxIB2oGtqLOfzw6DwIa01OHVAeF -> CFS MØN department local
// authorization: ls.get('api_token')

export const apolloClient = new ApolloClient({
    link: from([
        authLink,
        httpLink
    ]),
    cache: new InMemoryCache(),
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

Vue.use(VueApollo)

export default apolloProvider;