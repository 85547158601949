var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "buttonEl items-center text-base text-3xl rounded-lg h-18",
      class: {
        border:
          _vm.backgroundColorHex === "#fff" ||
          _vm.backgroundColorHex === "#ffffff",
        "px-10 py-6": _vm.padding,
        "shadow-xsm": _vm.shadow
      },
      style: [
        { backgroundColor: _vm.backgroundColorHex, color: _vm.textColorHex }
      ]
    },
    [
      _vm.icon
        ? _c("fa-icon", {
            staticClass: "mr-3",
            attrs: { icon: ["fal", "" + _vm.icon] }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t(_vm.text)))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }