
<template>
  <div class="activitiesWrapper" :class="{ 'verticalCenter flex items-center flex-col justify-center items-stretch': verticalCenterGrid || verticalCenterList }">
    <div v-if="activities.length" class="activities bg-white">
      
      <list-view
          v-if="activeContentView.name === 'list'"
          :dayItems="activityDays"
          :widthPercent="80"
          :sortByDay="true"
          @item-selected="showSingleActivity($event)"
      ></list-view>

      <grid-view
          v-if="activeContentView.name === 'grid'"
          :items="activities"
          itemType="activity"
          @item-selected="showSingleActivity($event)"
      ></grid-view>

      <content-filter
          v-if="activeContentView.name === 'filter'"
          :items="activityClubs"
          :activeItemIndex="selectedClubIndex"
          @filter-selected="clubSelected($event)"
      />
    </div>

    <div v-else class="noActivities h-full flex flex-col justify-center text-center mt-8" :class="`text-${activitiesColor}`">
      <p class="text-5xl font-bold mb-8" v-t="'activities.noActivities'"></p>
      <p class="text-3xl font-light" v-t="'activities.noActivitiesDescription'"></p>
    </div>
  </div>
</template>

<script>
import { isFuture } from 'date-fns';
import { mapGetters } from 'vuex';
import ContentFilter from '@/components/generic/ContentFilter';
import { cloneDeep } from 'lodash';
import { Carousel, Slide } from 'vue-carousel';
import moment from 'moment';
import ListView from '@/components/generic/ListView';
import GridView from '@/components/generic/GridView';
import { isItemNow, isItemToday, getWeekday, formatTime } from '@/utils/helpers.js';

export default {
  components: {
    ContentFilter,
    Carousel,
    Slide,
    ListView,
    GridView
  },
  data() {
    return {
      selectedClubIndex: -1,
      lastScrollTop: 0,
      topVisibleDay: null,
      singleActivityShown: false,
      selectedDate: new Date()
    };
  },
  async mounted() {
    await this.$store.dispatch('general/setActiveViewType', 'list');
    await this.$store.dispatch('general/setActiveContentView', this.$route.meta?.contentViews[0]);
  },
  computed: {
    ...mapGetters({
      fetchedActivities: 'activities/all',
      activeViewType: 'general/activeViewType',
      activeContentView: 'general/activeContentView',
      activeItemFilter: 'general/activeItemFilter'
    }),
    activitiesColor() {
      return this.$route?.meta?.color;
    },
    contentViews() {
      return this.$route?.meta?.contentViews;
    },
    activityDays() {
      const activities = cloneDeep(this.activities);
      return activities.reduce((accumulator, current, index) => {
        const uniqueDate = current.startDate.split('T')[0];

        if (accumulator[uniqueDate] || (accumulator[uniqueDate]=[])) {
          accumulator[uniqueDate].push(current);

          accumulator[uniqueDate].meta = {
            startDate: current.startDate,
            showTodayLabel: index === 0 && isItemToday(current.startDate)
          }
        };

        return accumulator;
      }, {});
    },
    activities() {
      return this.fetchedActivities.filter( activity => {
        return isItemNow(activity) ||
              isFuture(new Date(activity.startDate)) &&
              isFuture(new Date(activity.endDate)) &&
              (!this.activeItemFilter?.name || activity.club?.id === this.activeactiveItemFilterClubFilter?.id);
      }).map(activity => {
        return {
          ...activity,
          meta: {
            icon: 'clock',
            text: formatTime(activity)
          }
        }
      });
    },
    activityClubs() {
      return [...new Set (cloneDeep(this.fetchedActivities).filter(activity => activity.club).map(activity => activity.club))];
    },
    verticalCenterList() {
      return this.activeViewType === 'list' && this.activities.length < 3;
    },
    verticalCenterGrid() {
      return (this.activeViewType === 'grid' && this.activities.length < 10) || (this.activeContentView.name === 'filter' && this.activityClubs.length < 10);
    },
    daysWithActivities() {
      return [...new Set (cloneDeep(this.activities).map(activity => moment(activity.startDate).format('YYYY-MM-DD')))];
    },
    selectedDayActivities() {
      const carousel = document.querySelector('.VueCarousel-inner');
      if (carousel) carousel.style.transform = 'none';
      return cloneDeep(this.activities).filter(activity => moment(activity.startDate).format('YYYY-MM-DD') === this.selectedDate);
    }
  },
  methods: {
    showSingleActivity(activity) {
      if (activity.cancelled) return;

      const dayLabels = document.getElementsByClassName('dayLabelWrapper');

      for (let item of dayLabels) {
        item.style.zIndex = '0';
      }

      this.$router.push(`/activities/${activity.id}`);
    },
    clubSelected(index) {
      this.selectedClubIndex = index;
      const contentView = this.contentViews.find(filter => filter.name === this.activeViewType);
      this.$store.dispatch('general/setActiveContentView', contentView);
      this.$store.dispatch('general/setActiveItemFilter', this.activityClubs[index]);
    },
    formatTime(activity) {
      if(activity.cancelled) return this.$t('global.cancelled');
      return formatTime(activity);
    },
  }
};
</script>

<style lang="scss">
  .activitiesWrapper {
    position: relative;
    background: none;
    padding: 130px 0;

    &.verticalCenter .dayLabelWrapper {
      padding: 0;
    }

    &.gridView {
      padding-top: 15px;
    }
  }

</style>
