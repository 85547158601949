<template>
  <div class="w-full flex flex-col justify-between">
      <div class="flex flex-col items-center">
        <div class="largeListItemHeader pt-20 pb-8 px-10 text-center">{{ $t('profileDetails.header') }} {{ activeProfile.displayName }}</div>
        <div class="content mb-24 text-center">{{ $t('profileDetails.description') }}</div>
      </div>

      <div class="flex flex-col items-center">
        <img class="profileImage w-44 h-44 mb-4 rounded-full bg-cover" :src="activeProfile.displayImage" />
        <div class="content mb-28">{{ activeProfile.displayName }}</div>
      </div>

      <div class="flex flex-col items-center">
        <button-el text="global.logOut"
          icon="sign-out"
          backgroundColor="error"
          class="shadow-xsm mb-10"
          @click.native="logout"
        />

        <button-el text="global.close"
          icon="times"
          class="shadow-xsm mb-10"
          @click.native="$emit('closeOverlay')"
        />
      </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CoverView from '@/views/generic/CoverView';
import Button from '@/components/generic/Button';

export default {
  components: {
    CoverView,
    ButtonEl: Button
  },
  mounted() {
    this.selectedDepartment = this.department;
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile'
    }),
  },
  methods: {
    async logout() {
        await this.$store.dispatch('profiles/setActiveProfile', {});
        this.$emit('closeOverlay');
    }
  }
};
</script>
<style lang="scss" scoped="true">

</style>
