var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activitiesWrapper",
      class: {
        "verticalCenter flex items-center flex-col justify-center items-stretch":
          _vm.verticalCenterGrid || _vm.verticalCenterList
      }
    },
    [
      _vm.activities.length
        ? _c(
            "div",
            { staticClass: "activities bg-white" },
            [
              _vm.activeContentView.name === "list"
                ? _c("list-view", {
                    attrs: {
                      dayItems: _vm.activityDays,
                      widthPercent: 80,
                      sortByDay: true
                    },
                    on: {
                      "item-selected": function($event) {
                        return _vm.showSingleActivity($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContentView.name === "grid"
                ? _c("grid-view", {
                    attrs: { items: _vm.activities, itemType: "activity" },
                    on: {
                      "item-selected": function($event) {
                        return _vm.showSingleActivity($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeContentView.name === "filter"
                ? _c("content-filter", {
                    attrs: {
                      items: _vm.activityClubs,
                      activeItemIndex: _vm.selectedClubIndex
                    },
                    on: {
                      "filter-selected": function($event) {
                        return _vm.clubSelected($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "noActivities h-full flex flex-col justify-center text-center mt-8",
              class: "text-" + _vm.activitiesColor
            },
            [
              _c("p", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "activities.noActivities",
                    expression: "'activities.noActivities'"
                  }
                ],
                staticClass: "text-5xl font-bold mb-8"
              }),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "activities.noActivitiesDescription",
                    expression: "'activities.noActivitiesDescription'"
                  }
                ],
                staticClass: "text-3xl font-light"
              })
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }