var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-col relative",
      class: { isLoading: _vm.isLoading },
      attrs: { id: "app" }
    },
    [
      _vm.onStartPage
        ? _c("header-el", { staticClass: "flex-none" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "flex-grow relative overflow-y-scroll route-wrapper",
              class: _vm.routeClasses,
              style: { height: _vm.meta.height || "auto" }
            },
            [
              _c(
                "transition",
                {
                  attrs: {
                    "enter-active-class":
                      "animate__animated animate__slideInUp animationStarting",
                    "leave-active-class": "absolute w-full animationLeaving",
                    duration: 1300
                  }
                },
                [
                  _c("router-view", {
                    staticClass: "contentArea h-full bg-white"
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.meta.contentViews
        ? _c("content-views", {
            attrs: { contentViews: _vm.meta.contentViews },
            on: {
              "filter-selected": function($event) {
                return _vm.updateFilters($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("module-list", {
        staticClass: "flex-none bg-white shadow-sm pt-10 z-30"
      }),
      _vm._v(" "),
      _c("footer-el", {
        staticClass: "flex-none z-30 bg-white",
        on: {
          dimScreen: function($event) {
            _vm.dimScreen = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animate__animated animate__slideInUp",
            "leave-active-class": "animate__animated animate__slideOutDown",
            duration: 500
          }
        },
        [
          _c("keyboard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.keyboardInput !== null,
                expression: "this.keyboardInput !== null"
              }
            ],
            attrs: { input: this.keyboardInput }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("overlay", {
        attrs: { show: _vm.overlayHasValue && _vm.showOverlay },
        on: { closeOverlay: _vm.closeOverlay }
      }),
      _vm._v(" "),
      _vm.dimScreen
        ? _c("div", {
            staticClass:
              "screenDimmer absolute inset-0 z-50 bg-gray-900 bg-opacity-80",
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.dimScreen = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }