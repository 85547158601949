var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activity
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.activity.coverImage.source,
                gradient: true,
                "module-color": _vm.moduleColor,
                "margin-top": 40
              }
            },
            [
              !_vm.participantsDetailsShown.length
                ? _c("div", { staticClass: "activityContent" }, [
                    _c(
                      "div",
                      { staticClass: "largeListItemHeader pt-20 pb-10" },
                      [_vm._v(_vm._s(_vm.activity.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pictograms inline-flex w-full pb-20" },
                      _vm._l(_vm.activity.pictograms, function(
                        pictogram,
                        index
                      ) {
                        return _c("img", {
                          key: index,
                          staticClass: "w-20 mr-5",
                          attrs: { src: _vm.activity.pictograms[index].source }
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "activityDetails" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "activityDescription col-span-2 pl-16 relative content pb-10"
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "absolute top-1 left-0",
                            attrs: { icon: ["fal", "align-left"] }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.activity.description) +
                              "\n        "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "activityDate col-span-2 pl-16 relative content pb-10"
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "absolute top-1 left-0",
                            attrs: { icon: ["fal", "clock"] }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex flex-col" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.formatDate(_vm.activity)))
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.formatTime(_vm.activity)))
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.activity.signupPossible
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "activityDeadline col-span-2 pl-16 relative content pb-10"
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "alarm-clock"] }
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.formatDeadline(_vm.activity)) +
                                  "\n        "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "activityOccurence col-span-2 pl-16 relative content pb-10"
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "absolute top-1 left-0",
                            attrs: { icon: ["fal", "repeat"] }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatOccurence(_vm.activity)) +
                              "\n        "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !!_vm.activityPrice
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "activityPrice col-span-2 pl-16 relative content pb-10"
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "coins"] }
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.activityPrice) +
                                  "\n        "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activity.participants.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "activityResidents col-span-2 pl-16 relative content pb-10",
                              on: {
                                click: function($event) {
                                  return _vm.showProfiles("participants")
                                }
                              }
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "user-friends"] }
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.activity.participants, function(
                                person,
                                index
                              ) {
                                return _c("img", {
                                  key: index,
                                  staticClass:
                                    "w-10 h-10 rounded-full inline-block align-middle border border-gray-200",
                                  class: { "-m-1": index !== 0 },
                                  attrs: { src: person.displayImage }
                                })
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activity.responsible.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "activityEmployees col-span-2 pl-16 relative content pb-10",
                              on: {
                                click: function($event) {
                                  return _vm.showProfiles("responsible")
                                }
                              }
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "user-cog"] }
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.activity.responsible, function(
                                person,
                                index
                              ) {
                                return _c("img", {
                                  key: index,
                                  staticClass:
                                    "w-10 h-10 rounded-full inline-block align-middle border border-gray-200",
                                  class: { "-m-1": index !== 0 },
                                  attrs: { src: person.displayImage }
                                })
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activity.club
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "activityClub col-span-2 pl-16 relative content pb-10"
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "icons"] }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass:
                                  "w-10 h-10 rounded-full inline-block align-middle border border-gray-200",
                                attrs: { src: _vm.activity.club.logo.source }
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.activity.club.name) +
                                  "\n        "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activity.club
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "institution col-span-2 pl-16 relative label"
                            },
                            [
                              _c("fa-icon", {
                                staticClass: "absolute top-1 left-0",
                                attrs: { icon: ["fal", "hotel"] }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  style: {
                                    color: _vm.institutionColor || "#000"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.institutionName))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "activityActions flex fixed" },
                [
                  _vm.signupPossible
                    ? _c("button-el", {
                        staticClass: "mr-5 shadow-xsm",
                        attrs: {
                          text: "global.signUp",
                          icon: "check",
                          backgroundColor: "success",
                          textColor: "white"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.optInOrOut("in")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.optoutPossible
                    ? _c("button-el", {
                        staticClass: "shadow-xsm",
                        attrs: {
                          text: "global.optOut",
                          icon: "minus-circle",
                          backgroundColor: "error",
                          textColor: "white"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.optInOrOut("out")
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }