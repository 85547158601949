<template>
  <div ref="tick" class="tick">
    <div aria-hidden="true">
      <span data-key="hours" data-view="flip"></span>
      <span data-key="minutes" data-view="flip"></span>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

export default {
  name: "FlipClock",
  mounted() {
    this._tick = Tick.DOM.create(this.$refs.tick);

    Tick.helper.interval(() => {
        this._tick.value = {
          hours: format(new Date(), 'HH', { locale: da }),
          minutes: format(new Date(), 'mm', { locale: da })
        };
    });
  },
  destroyed() {
    Tick.DOM.destroy(this.$refs.tick);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tick {
  font-size: 2.7em;
  font-weight: 700;
  letter-spacing: -0.5em;
  .tick-flip{
    margin: 0;
    letter-spacing: 0.05em;
    text-indent: 0;
    padding: 0 3px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
    margin-right: 3px;
  }
  .tick-flip-panel {
    color: #323232;
    background-color: #FFFFFF;
  }
  .tick-flip-panel-back{
    box-shadow: none;
    &:after{
      background-image: none;
    }
  }
  .tick-flip-shadow{
    box-shadow: none;
  }
}
</style>
