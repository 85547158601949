<template>
    <div class="w-full">
      <div class="largeListItemHeader pb-4 pt-20 text-center">{{ $t('profileSelect.header') }}</div>
      <div class="content mb-20 text-center">{{ $t('profileSelect.description') }}</div>

      <div class="grid grid-cols-3 gap-y-4 gap-x-4 pb-20">
        <div v-for="(profile, index) in shownProfiles"
          :key="index"
          class="profile h-full"
          :class="{ 'inline': size !== 'large', 'stacked': size === 'large' }"
          @click="selectProfile(profile)"
        >
          <img class="profileImage rounded-full shadow-sm object-cover" :src="profile.displayImage" :class="size">
          <p class="content font-light">{{profile.displayName}}</p>
        </div>
      </div>

      <div class="buttonWrapper fixed flex flex-col bottom-0 pb-10 pt-4 w-full bg-white shadow-scroll">
        <select-el :options="departments"
          v-show="showSelectDepartment"
          class="self-center align-center z-2"
          :selectedOption="selectedDepartment"
          :hideSelectedOption="true"
          @option-selected="filterByDepartment"
          v-clickoutside="{excludeString: '.selectDepartment, .selectDepartment>*', handler: onClickoutside}"
        />

        <button-el
          :text="selectedDepartment.name"
          icon="house-user"
          :background-color="selectedDepartment.color"
          class="selectDepartment w-auto inline-block mb-10 self-center"
          @click.native="toggleShowSelectDepartment"
        />

        <button-el
          text="global.close"
          icon="times"
          :shadow="true"
          class="back w-auto inline-block self-center"
          @click.native="close"
        />

        <div @click="showResidents = !showResidents"
          class="profileTypeToggle rounded-full shadow-lg w-14 h-14 absolute bottom-14 right-14 flex justify-center items-center"
          :class="{ 'employeeSelected': !showResidents }"
        >
          <fa-icon class="text-2xl" :icon="['fal', 'id-card-alt']"/>
        </div>
      </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CoverView from '@/views/generic/CoverView';
import Button from '@/components/generic/Button';
import Select from '@/components/generic/Select';

export default {
  components: {
    CoverView,
    ButtonEl: Button,
    SelectEl: Select
  },
  data() {
    return {
      selectedDepartment: {},
      showSelectDepartment: false,
      showResidents: true
    }
  },
  mounted() {
    this.selectedDepartment = this.departments[0];
  },
  computed: {
    ...mapGetters({
      departments: 'settings/departments',
      profiles: 'profiles/all',
      department: 'general/department',
      activeProfile: 'profiles/activeProfile',
      activeOverlay: 'general/activeOverlay'
    }),
    shownProfiles() {
      const profilesInDepartment = this.profiles.filter(profile => this.getDepartmentIds(profile).includes(parseInt(this.selectedDepartment.id)));
      const profilesByProfileType = profilesInDepartment.filter(profile => profile.type === this.selectedProfileType);
      return profilesByProfileType;
    },
    size() {
      if (this.shownProfiles.length < 13) return 'large';
      if (this.shownProfiles.length < 25 && this.shownProfiles.length > 12) return 'medium';
      return 'small';
    },
    selectedProfileType() {
      return this.showResidents ? 'RESIDENT' : 'EMPLOYEE';
    }
  },
  methods: {
    getDepartmentIds(profile) {
      return profile.departments.map(department => parseInt(department.id));
    },
    filterByDepartment(index) {
      this.selectedDepartment = this.departments[index];
      this.showSelectDepartment = false;
    },
    onClickoutside() {
      if (!this.showSelectDepartment) return;
      this.showSelectDepartment = false;
    },
    toggleShowSelectDepartment() {
      this.showSelectDepartment = !this.showSelectDepartment;
    },
    selectProfile(profile) {
      this.$store.dispatch('profiles/setActiveProfile', profile);
      this.$emit('closeOverlay');
    },
    close() {
      this.$store.dispatch('general/resetOverlays', {});
      this.$emit('closeOverlay');
    }
  }
};
</script>
<style lang="scss" scoped="true">

  .profile {
    &.stacked {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &.inline {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .content {
        margin-left: 20px;
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
    }

    .profileImage {
      &.small {
        width: 72px;
        height: 72px;
        margin-bottom: 10px;
      }

      &.medium {
        width: 88px;
        height: 88px;
        margin-bottom: 45px;
      }

      &.large {
        width: 180px;
        height: 180px;
        margin-bottom: 40px;
      }
    }
  }

  .select-el {
    bottom: 250px;
  }

  .employeeSelected {
    background-color: #FF765B;
    color: white;
  }
</style>
