var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-views z-40" },
    _vm._l(_vm.contentViews, function(filter, index) {
      var _obj
      return _c(
        "div",
        {
          key: index,
          staticClass: "content-view rounded-full p-4 mr-5 text-4xl shadow-lg",
          class:
            ((_obj = {}),
            (_obj["bg-" + _vm.moduleColor] =
              _vm.activeContentView.name === filter.name),
            (_obj["bg-white"] = _vm.activeContentView.name !== filter.name),
            (_obj["text-white"] = _vm.activeContentView.name === filter.name),
            (_obj["separate"] = filter.position === "right"),
            _obj),
          on: {
            click: function($event) {
              return _vm.$emit("filter-selected", index)
            }
          }
        },
        [_c("fa-icon", { attrs: { icon: ["fal", "" + filter.icon] } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }