var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-row items-center justify-between w-full px-32" },
    [
      _c("button-el", {
        staticClass: "content bold",
        attrs: {
          text: "global.play",
          icon: "square",
          backgroundColor: "success"
        },
        nativeOn: {
          click: function($event) {
            return _vm.$emit("playVideo")
          }
        }
      }),
      _vm._v(" "),
      _c("button-el", {
        staticClass: "content bold",
        attrs: {
          text: "global.pause",
          icon: "square",
          backgroundColor: "success"
        },
        nativeOn: {
          click: function($event) {
            return _vm.$emit("pauseVideo")
          }
        }
      }),
      _vm._v(" "),
      _c("button-el", {
        staticClass: "content bold",
        attrs: {
          text: "global.restart",
          icon: "square",
          backgroundColor: "success"
        },
        nativeOn: {
          click: function($event) {
            return _vm.$emit("resetView")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }