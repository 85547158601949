<template>
  <div class="keyboardWrapper rounded-t-xl shadow p-4 pb-2 mx-4">
    <div :class="keyboardClass"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: 'keyboard',
  data(){
    return {
      keyboardClass: 'simple-keyboard',
      keyboard: null,
      layout: {
        'danish': [
          '$ 1 2 3 4 5 6 7 8 9 0 + ´ {bksp}',
          '{tab} q w e r t y u i o p å ¨ {enter}',
          '{lock} a s d f g h j k l æ ø \' {empty}',
          '{shift} < z x c v b n m , . - {shift}',
          '{space}'
        ],
        'shift_danish': [
          '§ ! " # € % & / ( ) = ? ` {bksp}',
          '{tab} Q W E R T Y U I O P Å ^ {enter}',
          '{lock} A S D F G H J K L Æ Ø * {empty}',
          '{shift} > Z X C V B N M ; : _ {shift}',
          '{space}'
        ],
        'numeric': [
          '1 2 3',
          '4 5 6',
          '7 8 9',
          '{empty} 0 {empty}'
        ]
      }
    }
  },
  props: {
    input: {
      type: String,
      'default': ''
    },
    layoutName: {
      type: String,
      'default': 'danish'
    },
    options: {
      type: Object,
      'default'(){
        return {
          preventMouseDownDefault: true
        };
      }
    }
  },
  computed: {
    ...mapGetters({
      keyboardCaretPosition: 'general/keyboardCaretPosition'
    }),
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      ...this.options,
      layout: this.layout,
      layoutName: this.layoutName,
      buttonTheme: [
        { class: 'hiddenBtn', buttons: '{empty}' },
        { class: 'doubleBtn', buttons: '{enter}' }
      ],
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    });
  },
  methods: {
    onChange(input) {
      this.$store.dispatch('general/setKeyboardInput', input);

      const caretPosition = this.keyboard.getCaretPosition();
      if (caretPosition !== null) this.$store.dispatch('general/setKeyboardCaretPosition', caretPosition);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === this.layoutName ? `shift_${this.layoutName}` : this.layoutName;

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  }
};
</script>
<style lang="scss">
.keyboardWrapper{
  position: fixed;
  bottom: 0;
  left: 25px;
  right: 25px;
  z-index: 50;
  background: white;
}
.hg-button{
  &.hiddenBtn{
    visibility: hidden;
    opacity: 0;
  }
  &.doubleBtn{
    height: 85px;
    margin-bottom: -45px;
  }
}
</style>
