import gql from "graphql-tag";

export default gql`
query myArchiveScreen ($profile: ScreenProfile!) {
  myArchiveScreen (profile: $profile) {
    documents {
      id
    }
    pictures {
      id
    }
    videos {
      id
    }
  }
}`