<template>
    <div>
      <div class="flex justify-between self-center p-20 rounded-t-3xl">
        <div class="largeListItemHeader">{{ $t(data.title) }}</div>
      </div>

      <div class="participants content inline-flex w-full pb-10 px-20" v-for="(profile, index) in data.profiles" :key="index">
        <img :src="profile.displayImage" :class="{ '-m-1': index !== 0 }" class="w-24 h-24 rounded-full border border-gray-200 mr-10"/>
        <div class="details">
          <p class="label">{{ profile.displayName }}</p>
          <span :style="{ 'color': getDepartmentColor(profile) }">
            <fa-icon :icon="['fal', 'house-user']"/>
            {{ getDepartmentName(profile) }}
          </span>
        </div>
      </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { get } from 'lodash';

  export default {
    computed: {
      ...mapGetters({
        activeOverlay: 'general/activeOverlay'
      }),
      data() {
        return this.activeOverlay.data;
      }
    },
    methods: {
      getDepartmentName(profile) {
        return get(profile, 'departments[0].name', '');
      },
      getDepartmentColor(profile) {
        return get(profile, 'departments[0].color', 'charcoal');
      }
    }
  }
</script>