var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "flex charcoal" },
    [
      _c(
        "div",
        {
          staticClass:
            "date flex-none text-lg leading-none flex flex-col justify-center"
        },
        [
          _c("p", { staticClass: "dayOfWeek font-bold" }, [
            _vm._v(_vm._s(_vm.dayOfWeek))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "date font-light" }, [
            _vm._v(_vm._s(_vm.date))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions flex-grow flex justify-center text-3xl" },
        [
          _c(
            "div",
            {
              staticClass: "action flex justify-center items-center",
              class: { disabled: !_vm.canGoBackInHistory },
              on: { click: _vm.goBackInHistory }
            },
            [_c("fa-icon", { attrs: { icon: ["fal", "arrow-left"] } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "action flex justify-center items-center",
              on: { click: _vm.selectProfile }
            },
            [
              !_vm.activeProfile.displayImage
                ? _c("fa-icon", { attrs: { icon: ["fal", "user-circle"] } })
                : _c("img", {
                    staticClass: "profileImage",
                    attrs: { src: _vm.activeProfile.displayImage }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "action flex justify-center items-center",
              class: { disabled: _vm.onStartPage },
              attrs: { disabled: _vm.onStartPage, to: "/" }
            },
            [_c("fa-icon", { attrs: { icon: ["fal", "th"] } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "action flex justify-center items-center",
              on: {
                click: function($event) {
                  return _vm.$emit("dimScreen")
                }
              }
            },
            [_c("fa-icon", { attrs: { icon: ["fal", "lightbulb-slash"] } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action flex justify-center items-center" },
            [_c("fa-icon", { attrs: { icon: ["fal", "universal-access"] } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "action flex justify-center items-center text-red-300",
              on: { click: _vm.feedbackAction }
            },
            [_c("fa-icon", { attrs: { icon: ["fal", "question-circle"] } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("video", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "video"
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "clock flex-none text-right flex flex-col justify-center"
        },
        [_c("flip-clock")],
        1
      ),
      _vm._v(" "),
      _vm.showFeedbackDialog
        ? _c("feedback-dialog", {
            on: {
              cancel: function($event) {
                _vm.showFeedbackDialog = false
              },
              startRecording: _vm.startFeedbackCapture
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showStopFeedbackDialog
        ? _c("stop-feedback-dialog", {
            on: {
              cancel: function($event) {
                _vm.showStopFeedbackDialog = false
              },
              stopRecording: _vm.stopFeedbackCapture
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isRecording ? _c("recording-notification") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }