var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "module-list z-10 text-center space-x-4 flex flex-wrap justify-center content-center"
    },
    _vm._l(_vm.modules, function(module, index) {
      var _obj, _obj$1, _obj$2
      return _c(
        "router-link",
        {
          key: index,
          staticClass:
            "module rounded text-white justify-center items-center flex flex-col",
          class:
            ((_obj = {}),
            (_obj["bg-" + module.meta.color] = _vm.activePath === module.path),
            _obj),
          attrs: { to: module.path, "active-class": "active" }
        },
        [
          _c("fa-icon", {
            staticClass: "text-3xl",
            class:
              ((_obj$1 = {}),
              (_obj$1["text-" + module.meta.color] =
                _vm.activePath !== module.path),
              _obj$1),
            attrs: { icon: module.meta.icon }
          }),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: module.name,
                expression: "module.name"
              }
            ],
            staticClass: "text-sm mt-1 font-bold",
            class:
              ((_obj$2 = {}),
              (_obj$2["text-" + module.meta.color] =
                _vm.activePath !== module.path),
              _obj$2)
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }