var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "animate__animated animate__slideInUp",
        "leave-active-class": "animate__animated animate__slideOutDown",
        duration: 1300
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          staticClass:
            "overlay flex flex-col w-full fixed bottom-0 left-0 fixed h-full",
          class: { "z-40": _vm.activeOverlay.hideNav },
          style: _vm.overlayStyles
        },
        [
          _c(
            "div",
            { staticClass: "backdrop bg-white", style: _vm.backdropStyles },
            [
              _vm.activeOverlay.canClose
                ? _c("fa-icon", {
                    staticClass:
                      "text-5xl h-auto font-bold absolute right-20 mt-20",
                    attrs: { icon: ["fal", "times"] },
                    on: {
                      click: function($event) {
                        return _vm.$emit("closeOverlay")
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(_vm.activeOverlay.name, {
                tag: "component",
                on: {
                  closeOverlay: function($event) {
                    return _vm.$emit("closeOverlay")
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }