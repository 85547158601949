<template>
  <header :class="{ onStartPage }" class="flex items-center justify-center">
    <img v-if="onStartPage" class="logo" :src="logoSource" />
    <p v-else class="title text-3xl font-bold">
      <fa-icon :icon="$route.meta.icon"/>
      {{$t($route.name)}}
    </p>
  </header>
</template>
<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
export default {
  computed: {
    ...mapGetters({
      institution: 'settings/logo'
    }),
    onStartPage() {
      return this.$route.path === '/';
    },
    logoSource() {
      return get(this, 'logo.source', '');
    }
  },
}
</script>
<style lang="scss">
  header{
    height: 80px;
    &.onStartPage{
      height: 140px;
    }
    .logo{
      max-height: 120px;
    }
  }
</style>
