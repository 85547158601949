var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "tick", staticClass: "tick" }, [_vm._m(0)])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { "aria-hidden": "true" } }, [
      _c("span", { attrs: { "data-key": "hours", "data-view": "flip" } }),
      _vm._v(" "),
      _c("span", { attrs: { "data-key": "minutes", "data-view": "flip" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }