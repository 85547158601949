<template>
  <div class="flex">
    <VueDatePicker
          v-model="date"
          :color="color"
          :inline="showOnDefault"
          :no-header="hideHeader"
          :min-date="minDate"
          :allowed-dates="allowedDates"
          :locale="locale"
          @onChange="$emit('changeDate', date)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showOnDefault: {
      type: Boolean,
      'default': true
    },
    hideHeader: {
      type: Boolean,
      'default': true
    },
    color: {
      type: String,
      'default': '000'
    },
    showPastDates: {
      type: Boolean,
      'default': false
    },
    daysWithContent: {
      type: Array,
      'default': (() => [])
    }
  },
  data: () => ({
    date: new Date(),
    langList: {
      da: {
        name: 'da',
        weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
        weekdaysShort: 'Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lør'.split('_'),
        weekStart: 1,
        months: 'Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December'.split('_'),
        monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_')
      }
    }
  }),
  computed: {
    ...mapGetters({
      language: 'settings/locale'
    }),
    minDate() {
      return this.showPastDates ? undefined : new Date();
    },
    locale() {
      return {
        lang: this.langList[this.language] || this.langList.da
      }
    }
  },
  methods: {
    allowedDates(date) {
      return this.daysWithContent.includes(date);
    }
  }
}
</script>

<style lang="scss">
.vd-wrapper {
  box-shadow: none;
  justify-content: center;
  line-height: 2.5rem;
  font-weight: 300;

  .vd-picker {
    width: 500px;
    box-shadow: none;

    &__body {
      overflow: visible;
    }

    &__controls {

      &-prev, &-next {
        position: absolute;
        top: 180px;
      }

      &-prev {
        left: -40px;
      }

      &-next {
        right: -40px;
      }

      &-wrapper {
        margin-bottom: 1rem;
      }
      
      &-month {
        display: flex;
        justify-content: center;

        button {
          font-size: 1.8rem;
          font-weight: 700;
        }
      }

      &-year {
        display: none;
      }
    }

    &__table {
      height: auto;
    }

    &__table-days__wrapper {
      height: 260px;
    }

    &__table-day {
      font-size: 1.6rem;
      width: 68px;
      height: 68px;

      &--selected {
        font-weight: 700;
        
        .vd-picker__table-day__effect {
          background: #69C200;
          border: 8px solid rgba(105, 194, 0, 0.25);
        }
      }

      &__effect, &__current, &__text {
        top: unset;
        width: 48px;
        height: 48px;
      }
    }
  }
}
</style>
