<template>
<div>
  <cover-view v-if="activity" :image-url="activity.coverImage.source" :gradient="true" :module-color="moduleColor" :margin-top="40">

    <div v-if="!participantsDetailsShown.length" class="activityContent">
      <div class="largeListItemHeader pt-20 pb-10">{{activity.title}}</div>

      <div class="pictograms inline-flex w-full pb-20">
        <img v-for="(pictogram, index) in activity.pictograms" 
          class="w-20 mr-5"
          :key="index"
          :src="activity.pictograms[index].source"
        />
      </div>

      <div class="activityDetails">
        <div class="activityDescription col-span-2 pl-16 relative content pb-10">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'align-left']"/>
          {{activity.description}}
        </div>

        <div class="activityDate col-span-2 pl-16 relative content pb-10">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'clock']"/>
          <div class="flex flex-col">
            <span>{{formatDate(activity)}}</span>
            <span>{{formatTime(activity)}}</span>
          </div>
        </div>

        <div v-if="activity.signupPossible" class="activityDeadline col-span-2 pl-16 relative content pb-10">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'alarm-clock']"/>
          {{formatDeadline(activity)}}
        </div>

        <div class="activityOccurence col-span-2 pl-16 relative content pb-10">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'repeat']"/>
          {{formatOccurence(activity)}}
        </div>

        <div v-if="!!activityPrice" class="activityPrice col-span-2 pl-16 relative content pb-10">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'coins']"/>
          {{activityPrice}}
        </div>

        <div v-if="activity.participants.length" class="activityResidents col-span-2 pl-16 relative content pb-10" @click="showProfiles('participants')">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'user-friends']"/>
          <img v-for="(person, index) in activity.participants" :key="index" :src="person.displayImage" :class="{ '-m-1': index !== 0 }" class="w-10 h-10 rounded-full inline-block align-middle border border-gray-200"/>
        </div>

        <div v-if="activity.responsible.length" class="activityEmployees col-span-2 pl-16 relative content pb-10" @click="showProfiles('responsible')">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'user-cog']"/>
          <img v-for="(person, index) in activity.responsible" :key="index" :src="person.displayImage" :class="{ '-m-1': index !== 0 }" class="w-10 h-10 rounded-full inline-block align-middle border border-gray-200"/>
        </div>

        <div v-if="activity.club" class="activityClub col-span-2 pl-16 relative content pb-10">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'icons']"/>
          <img :src="activity.club.logo.source" class="w-10 h-10 rounded-full inline-block align-middle border border-gray-200"/>
          {{activity.club.name}}
        </div>

        <div v-if="activity.club" class="institution col-span-2 pl-16 relative label">
          <fa-icon class="absolute top-1 left-0" :icon="['fal', 'hotel']"/>
          <span :style="{ color: institutionColor || '#000' }">{{ institutionName }}</span>
        </div>
      </div>
    </div>

    <div class="activityActions flex fixed">
      <button-el v-if="signupPossible"
        text="global.signUp"
        icon="check"
        backgroundColor="success"
        textColor="white"
        class="mr-5 shadow-xsm"
        @click.native="optInOrOut('in')"
      />

      <button-el v-if="optoutPossible"
        text="global.optOut"
        icon="minus-circle"
        backgroundColor="error"
        textColor="white"
        class="shadow-xsm"
        @click.native="optInOrOut('out')"
      />
    </div>

  </cover-view>
  </div>
</template>
<script>
import { format, isSameDay, isToday, isTomorrow, isThisWeek, isBefore, isAfter, isFuture, parseISO, formatDistanceToNow } from 'date-fns';
import { da } from 'date-fns/locale';
import { mapGetters } from 'vuex';
import Button from '@/components/generic/Button';
import { capitalize, get, isEmpty } from 'lodash';
import CoverView from '@/views/generic/CoverView';
import Overlay from '@/views/overlays/Overlay';

export default {
  components: {
    ButtonEl: Button,
    CoverView,
    Overlay
  },
  data() {
    return {
      participantsDetailsShown: '',
      opt: ''
    }
  },
  computed: {
    ...mapGetters({
      fetchedActivities: 'activities/all',
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'general/pinValidation',
      institutionColor: 'settings/color',
      institutionName: 'settings/name'
    }),
    moduleColor() {
      return this.$route?.meta?.color;
    },
    activity() {
      return this.fetchedActivities.find(activity => activity.id === this.$route.params.id);
    },
    activityPrice() {
      if (!get(this, 'activity.price', false)) return null;
      return new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(this.activity.price[0].price / 100);
    },
    signedUp() {
      const hasSelectedProfile = !!get(this, 'activeProfile.displayName', '').length;
      
      if (!hasSelectedProfile) return false;

      const allProfiles = this.activity.participants.concat(this.activity.responsible);
      return !!allProfiles.filter(profile => profile.id === this.activeProfile.id && profile.type === this.activeProfile.type).length;
    },
    signupPossible() {
      return this.activity.signupPossible && !this.signedUp;
    },
    optoutPossible() {
      if (!this.signedUp) return false;
      return isFuture(new Date(this.activity.startDate)) && !this.activity.cancelled;
    },
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validating) return;

      const signupData = {
        subscription: {
          id: this.activity.id
        },
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString()
        },
        opt: this.opt
      };

      this.$store.dispatch('activities/activitySignupScreen', signupData);
    }
  },
  methods: {
    getCoverImage(activity) {
      if(activity.coverImage) return activity.Resources.find(resource => resource.id === activity.coverImage).path;

      return activity.Resources.filter( resource => resource.type === 'image')[0].path;
    },
    isActivityNow(activity){
      return isAfter(new Date(), parseISO(activity.startDate)) && isBefore(new Date(), parseISO(activity.endDate));
    },
    isActivityFirstOnDate(activity, index){
      if(index === 0) return true;

      return !isSameDay(new Date(activity.startDate), new Date(this.fetchedActivities[index - 1].startDate));
    },
    isActivityToday(dateString){
      return isToday(new Date(dateString));
    },
    formatDayLabel(dateString){
      const date = new Date(dateString);
      if(isToday(date)) return this.$t('global.today');
      if(isTomorrow(date)) return this.$t('global.tomorrow');
      if(isThisWeek(date, { locale: da})) return format(date, 'EEEE', { locale: da});

      return format(date, 'EEEE dd MMMM', { locale: da });
    },
    formatTime(activity){
      const startTime = format(new Date(activity.startDate), 'HH:mm', { locale: da });
      const endTime = format(new Date(activity.endDate), 'HH:mm', { locale: da });
      return `${startTime} - ${endTime}`;
    },
    formatDate(activity) {
      //TODO: Handle end date being a different day than start (vacations)
      return capitalize(format(new Date(activity.startDate), 'EEEE dd MMMM', { locale: da }));
    },
    formatDeadline(activity) {
      return `${this.$t('global.signUpDeadline')} ${formatDistanceToNow(new Date(activity.signupEnd), { addSufix: true, locale: da })}`;
    },
    formatOccurence(activity){
      return 'Every week';
    },
    setScrollTop(scroll) {
      document.querySelector('.route-wrapper').scrollTop = scroll;
    },
    showProfiles(profileType) {
      this.$store.dispatch('general/setActiveOverlay', { name: 'profiles-list', data: { title: `global.${profileType}`, profiles: this.activity[profileType] } })
    },
    async optInOrOut(opt) {
      this.opt = opt;
      const profileSelected = !isEmpty(this.activeProfile);

      const pinOverlayData = {
        entityName: this.activity.title,
        title: `${this.$t('modals.writePassword')} ${this.$t(`modals.writePasswordOpt${opt}`)} ${this.$t('activity.theActivity')}`,
        titleSuccess: `${this.$t(`global.${opt === 'in' ? 'youAreSignedUp' : 'youOptedOut'}`)} ${this.$t('activity.theActivity')}`,
        opt
      };

      this.$store.dispatch('general/setActiveOverlay', !profileSelected ? { name: 'profile-select' } : { name: 'enter-pin', data: pinOverlayData });

      if (!profileSelected) this.$store.dispatch('general/setNextActiveOverlay', { name: 'enter-pin', data: pinOverlayData });
    }
  }
};
</script>

<style lang="scss">
  .activityContent {

    &.participants {
      margin-top: 700px;
    }

    > * {
      padding: 0 100px;
    }
  }

  .activityActions {
    width: fit-content;
    bottom: 375px;
    right: 79px;
  }
</style>
