var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "coverView" }, [
    _c("div", {
      staticClass: "backgroundWrap bg-no-repeat bg-cover fixed w-full",
      style: _vm.styles
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "viewContent w-full",
        style: { marginTop: this.marginTop + "vh" }
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }