import gql from "graphql-tag";

export default gql`
query activityRange ($filter: Filter!, $dateFrom: Date!, $dateTo: Date!, $timezone: TimeZone!) {
    activityRange (filter: $filter, dateFrom: $dateFrom, dateTo: $dateTo, timezone: $timezone) {
        ... on SingleActivity {
            id
            title
            coverImage {
                id
                source
            }
            pictograms {
                id
                source
            }
            pictures {
                id
                source
            }
            videos {
                id
                provider
                url
                description
                thumbnail
            }
            documents {
                id
                url
            }
            startDate
            endDate
            signupStart
            signupEnd
            maxSignups
            signupPossible
            description
            location
            club {
                id
                logo {
                    id
                    source
                }
                name
                color
            }
            price {
                price
                currency
            }
            responsible {
                ... on Employee {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
                ... on Resident {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
            }
            participants {
                ... on Employee {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
                ... on Resident {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
            }
            participating
            cancelled
            groups {
                id
                color
                participants {
                    ... on Employee {
                        id
                    }
                    ... on Resident {
                        id
                    }
                }
                description
            }
        }
        ... on RepeatingActivity {
            repeat {
                description
                rrule
                rruleText
            }
            id
            title
            coverImage {
                id
                source
            }
            pictograms {
                id
                source
            }
            pictures {
                id
                source
            }
            videos {
                id
                provider
                url
                description
                thumbnail
            }
            documents {
                id
                url
            }
            startDate
            endDate
            signupStart
            signupEnd
            maxSignups
            signupPossible
            description
            location
            club {
                id
                logo {
                    id
                    source
                }
                name
                color
            }
            price {
                price
                currency
            }
            responsible {
                ... on Employee {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
                ... on Resident {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
            }
            participants {
                ... on Employee {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
                ... on Resident {
                    type
                    id
                    displayImage
                    showImage
                    displayName
                    departments {
                        name
                        color
                    }
                }
            }
            participating
            cancelled
            groups {
                id
                color
                participants {
                    ... on Employee {
                        id
                    }
                    ... on Resident {
                        id
                    }
                }
                description
            }
        }
    }
}`