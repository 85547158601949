var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "keyboardWrapper rounded-t-xl shadow p-4 pb-2 mx-4" },
    [_c("div", { class: _vm.keyboardClass })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }