import { apolloClient } from '../../utils/apollo';
import store from '@/store';

const state = {
    all: [],
    activeProfile: {},
    myArchive: {}
};

const mutations = {
    SET_PROFILES(state, profiles) {
        state.all = profiles;
    },
    SET_ACTIVE_PROFILE(state, activeProfile) {
        state.activeProfile = activeProfile;
    },
    SET_MY_ARCHIVE(state, myArchive) {
        state.myArchive = myArchive;
    }
};

const actions = {
    async getProfiles({ commit }) {
        const departmentIds = store.getters['settings/departments'].map(department => department.id);

        await apolloClient
            .query({
                query: require("@/graphql/queries/profiles").default,
                variables: {
                    filter: {
                        departments: departmentIds
                    }
                }
            })
            .then(response => {
                commit('SET_PROFILES', response.data.profiles);
            })
            .catch(error => {
                console.log('Error getting profiles', error);
            });
    },
    setActiveProfile({commit}, activeProfile) {
        commit('SET_ACTIVE_PROFILE', activeProfile);
    },
    async getMyArchive({ commit }) {
        await apolloClient
            .query({
                query: require('@/graphql/queries/myArchiveScreen').default,
                variables: {
                    profile: {
                        profileType: state.activeProfile.type,
                        profileId: state.activeProfile.id
                    }
                },
                fetchPolicy: 'no-cache'
            }).then(response => {
                commit('SET_MY_ARCHIVE', response.data.myArchiveScreen);
            }).catch(error => {
                console.log('Error getting my archive', error);
            });
    }
};

const getters = {
    all: state => state.all,
    activeProfile: state => state.activeProfile,
    myArchive: state => state.myArchive
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};