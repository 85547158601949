<template>
  <div class="mediaIcons absolute -top-28 right-10 h-20 flex justify-end w-full">
    <div v-for="(icon, index) in icons"
        :key="index"
        class="rounded rounded-full bg-white w-20 h-20 ml-6 flex justify-center items-center relative"
        :class="{ [`bg-${moduleColor}`]: mediaType === icon.name }"
    >
        <fa-icon
              :icon="['fal', icon.fa]" 
              class="text-4xl"
              :class="{ 'text-white': mediaType === icon.name, [`text-${moduleColor}`]: mediaType !== icon.name }"
              @click="$emit('media-selected', icon.name)"
        />

        <div class="rounded rounded-full border border-white border-2 text-white absolute -right-1 -bottom-1 w-8 h-8 flex items-center justify-center text-xl font-bold" :class="`bg-${moduleColor}`">
          {{ icon.amount }}
        </div>
    </div>
</div>
</template>

<script>
  export default {
    props: {
      icons: {
        type: Array,
        'default': (() => [])
      },
      mediaType: {
        type: String,
        'default': ''
      }
    },
    computed: {
      moduleColor() {
        return this.$route.meta?.color;
      }
    }
  }
</script>