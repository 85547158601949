import { apolloClient } from '../../utils/apollo';
import store from '@/store';

const state = {
  all: []
};

const mutations = {
  SET_GALLERIES(state, galleries) {
    state.all = galleries;
  }
};

const actions = {
  async getGalleries({commit}) {
    await apolloClient
      .query({
        query: require('@/graphql/queries/galleries').default,
      })
      .then(response => {
        commit('SET_GALLERIES', response.data.galleries);
      })
      .catch(error => {
        console.log('Error getting galleries', error);
      })
  },
  async archiveAddScreen({commit}, { resourceId, profile, opt }) {
    const mutation = opt === 'in' ? 'archiveAddScreen' : 'archiveRemoveScreen';

    await apolloClient
      .mutate({
        mutation: require(`@/graphql/mutations/${mutation}`).default,
        variables: {
          resourceId,
          profile
        },
      })
      .then(async () => {
        // TODO: Add to sentry
        await store.dispatch('general/setPinValidation', { validating: false, validPin: true });
        store.dispatch('profiles/getMyArchive');
      })
      .catch(error => {
        // TODO: Add to sentry
        store.dispatch('general/setPinValidation', { validating: false, validPin: false });
      });
  }
};

const getters = {
  all: state => state.all
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
