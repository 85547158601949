import overlays from '@/utils/overlays';

const state = {
  overlays,
  keyboard: {
    input: null,
    caretPosition: null
  },
  isLoading: true,
  activeOverlay: {},
  nextActiveOverlay: {},
  activeContentView: {},
  activeViewType: null,
  activeItemFilter: {},
  department: {},
  pinValidation: {}
};

const mutations = {
  SET_KEYBOARD_INPUT(state, input) {
    state.keyboard.input = input;
  },
  SET_KEYBOARD_CARET_POSITION(state, position) {
    state.keyboard.caretPosition = position;
  },
  SET_LOADING(state, loading){
    state.isLoading = loading;
  },
  SET_DEPARTMENT_DATA(state, department) {
    state.department = department;
  },
  SET_ACTIVE_OVERLAY(state, activeOverlay) {
    state.activeOverlay = activeOverlay;
  },
  SET_NEXT_ACTIVE_OVERLAY(state, nextActiveOverlay) {
    state.nextActiveOverlay = nextActiveOverlay;
  },
  RESET_OVERLAYS(state) {
    state.activeOverlay = {};
    state.nextActiveOverlay = {};
  },
  SET_ACTIVE_CONTENT_FILTER(state, activeContentView) {
    state.activeContentView = activeContentView;
  },
  SET_ACTIVE_VIEW_TYPE(state, activeViewType) {
    state.activeViewType = activeViewType;
  },
  SET_ACTIVE_ITEM_FILTER(state, activeItemFilter) {
    state.activeItemFilter = activeItemFilter;
  },
  SET_PIN_VALIDATION(state, pinValidation) {
    state.pinValidation = pinValidation;
  }
};

const actions = {
  setKeyboardInput({commit}, input) {
    commit('SET_KEYBOARD_INPUT', input);
  },
  setKeyboardCaretPosition({commit}, caretPosition) {
    commit('SET_KEYBOARD_CARET_POSITION', caretPosition);
  },
  closeKeyboard({commit}) {
    commit('SET_KEYBOARD_INPUT', null);
    commit('SET_KEYBOARD_CARET_POSITION', null);
  },
  setLoading({commit}, loading) {
    commit('SET_LOADING', loading);
  },
  setDepartmentData({commit}, data) {
    commit('SET_DEPARTMENT_DATA', data);
  },
  setActiveOverlay({commit}, activeOverlay) {
    const overlay = state.overlays.find(overlay => overlay.name === activeOverlay.name);
    if (activeOverlay.data) overlay.data = activeOverlay.data;
    commit('SET_ACTIVE_OVERLAY', overlay || {});
  },
  setNextActiveOverlay({commit}, nextActiveOverlay) {
    commit('SET_NEXT_ACTIVE_OVERLAY', nextActiveOverlay);
  },
  resetOverlays({commit}) {
    commit('RESET_OVERLAYS');
  },
  setActiveContentView({commit}, activeContentView) {
    commit('SET_ACTIVE_CONTENT_FILTER', activeContentView);
  },
  setActiveViewType({commit}, activeViewType) {
    document.querySelector('.route-wrapper').scrollTop = 600;
    commit('SET_ACTIVE_VIEW_TYPE', activeViewType);
  },
  setActiveItemFilter({commit}, activeItemFilter) {
    commit('SET_ACTIVE_ITEM_FILTER', activeItemFilter);
  },
  setPinValidation({commit}, pinValidation) {
    commit('SET_PIN_VALIDATION', pinValidation);
  }
};

const getters = {
  keyboardInput: state => state.keyboard.input,
  keyboardCaretPosition: state => state.keyboard.caretPosition,
  isLoading: state => state.isLoading,
  department: state => state.department,
  activeOverlay: state => state.activeOverlay,
  nextActiveOverlay: state => state.nextActiveOverlay,
  activeContentView: state => state.activeContentView,
  activeViewType: state => state.activeViewType,
  activeItemFilter: state => state.activeItemFilter,
  overlays: state => state.overlays,
  pinValidation: state => state.pinValidation
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
