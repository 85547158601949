<template>
  <div id="app" :class="{isLoading}" class="flex flex-col relative">
    <header-el v-if="onStartPage" class="flex-none"/>
    <div
        v-if="!isLoading"
        class="flex-grow relative overflow-y-scroll route-wrapper"
        :style="{ height: meta.height || 'auto' }"
        :class="routeClasses"
    >
      <transition
          enter-active-class="animate__animated animate__slideInUp animationStarting"
          leave-active-class="absolute w-full animationLeaving"
          :duration="1300"
      >
        <router-view class="contentArea h-full bg-white" />
      </transition>
    </div>

    <content-views v-if="meta.contentViews" :contentViews="meta.contentViews" @filter-selected="updateFilters($event)" />
    <module-list class="flex-none bg-white shadow-sm pt-10 z-30" />
    <footer-el class="flex-none z-30 bg-white" @dimScreen="dimScreen=true"/>

    <transition enter-active-class="animate__animated animate__slideInUp" leave-active-class="animate__animated animate__slideOutDown" :duration="500">
      <keyboard v-show="this.keyboardInput !== null" :input="this.keyboardInput"/>
    </transition>

    <overlay :show="overlayHasValue && showOverlay" @closeOverlay="closeOverlay" />

    <div v-if="dimScreen" class="screenDimmer absolute inset-0 z-50 bg-gray-900 bg-opacity-80" @click.stop="dimScreen=false"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import Header from '@/components/generic/Header';
import ModuleList from '@/components/generic/ModuleList';
import Footer from '@/components/generic/Footer';
import Keyboard from '@/components/generic/Keyboard';
import Overlay from '@/views/overlays/Overlay';
import contentViews from '@/components/generic/ContentViews';

export default {
  components: {
    HeaderEl: Header,
    ModuleList,
    FooterEl: Footer,
    Keyboard,
    Overlay,
    contentViews
  },
  data() {
    return {
      dimScreen: false,
      showOverlay: false
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'general/isLoading',
      keyboardInput: 'general/keyboardInput',
      activeOverlay: 'general/activeOverlay',
      nextActiveOverlay: 'general/nextActiveOverlay',
      activeViewType: 'general/activeViewType',
      activecontentView: 'general/activecontentView',
      activeItemFilter: 'general/activeItemFilter'
    }),
    onStartPage() {
      return this.$route.path === '/';
    },
    meta() {
      return this.$route.meta || {};
    },
    overlayHasValue() {
      return !!get(this, 'activeOverlay.name', '').length;
    },
    routeClasses() {
      return {
        'scroll-up': this.meta.scrollUp,
        [`bg-${this.meta.color}`]: !!this.meta.scrollUp
      };
    },
  },
  watch: {
    overlayHasValue(newValue) {
      if (!!newValue) this.showOverlay = true;
    },
    '$route'(newValue) {
      this.$nextTick(() => {
        if (newValue.meta.scrollUp) {
          this.scrollToContent('scroll-up', 600);
        } else {
          this.scrollToContent('route-wrapper', 0);
        }
      });
    },
    '$store.state.idleVue.isIdle'(newValue) {
      // go to start page and reload
      if (!!newValue) {
        this.$router.push('/');
        this.$router.go();
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('settings/getSettings');

    await Promise.all([
      this.$store.dispatch('galleries/getGalleries'),
      this.$store.dispatch('activities/getActivities'),
      this.$store.dispatch('profiles/getProfiles'),
    ]);

    this.$store.dispatch('general/setLoading', false);

    // console.log(this.$matomo);

    // this.$matomo.trackPageView(['start-page']);

    this.scrollToContent('scroll-up', 600);
  },
  methods: {
    closeOverlay() {
      const hasNextOverlay = !!get(this, 'nextActiveOverlay.name', '').length;
      this.showOverlay = false;

      setTimeout(() => {
        this.$store.dispatch('general/setActiveOverlay', hasNextOverlay ? this.nextActiveOverlay : {});

        if (hasNextOverlay) {
          this.showOverlay = true;
          this.$store.dispatch('general/setNextActiveOverlay', {});
        }
      }, 1300); // wait for the transition to end before we change the store value
    },
    updateFilters(index) {
      const filter = this.meta.contentViews[index];
      this.$store.dispatch('general/setActiveContentView', filter);

      if ([ 'list', 'grid' ].indexOf(filter.name) > -1) {
        this.$store.dispatch('general/setActiveViewType', filter.name);
      }
    },
    scrollToContent(className, xValue) {
      const selector = document.querySelector(`.${className}`);
      if (selector) selector.scrollTop = xValue;
    }
  },
};
</script>
<style lang="scss">
  #app {
    width: 1080px;
    height: 1920px;
    margin: auto;
    background: white;

    .route-wrapper {
      margin-bottom: -60px;

      &.scroll-up .contentArea {
        margin-top: 600px;
      }
    }
  }
  .contentArea {
     animation-duration: 1.3s;
  }
  .simple-keyboard{
     animation-duration: 0.5s;
  }
  .animationStarting * {
    opacity: 1;
  }
  .animationLeaving * {
    transition: opacity 500ms ease-in-out;
    opacity: 0;
  }
</style>
