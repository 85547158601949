import { format, isToday, isBefore, isAfter, parseISO } from 'date-fns';
import { capitalize } from 'lodash';
import { da } from 'date-fns/locale';
import colors from '@/utils/colors.js';

export function isItemNow(item) {
  return isAfter(new Date(), parseISO(item.startDate)) && isBefore(new Date(), parseISO(item.endDate));
};

export function isItemToday(dateString) {
  return isToday(new Date(dateString));
};

export function getWeekday(item) {
  const date = new Date(item.startDate);
  return capitalize(format(date, 'EEEE', { locale: da}))
};

export function formatTime(item) {
  return format(new Date(item.startDate), 'HH:mm', { locale: da });
};

export function hexToRgbA(hex, alpha) {
  let color;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      color = hex.substring(1).split('');

      if (color.length === 3) {
        color = [color[0], color[0], color[1], color[1], color[2], color[2]];
      }

      color = '0x'+color.join('');
      const colorParts = [(color>>16)&255, (color>>8)&255, color&255];
      
      return `rgba(${colorParts.join(',')}, ${alpha})`;
  }
};

export function getAATextColor(backgroundColor) {
  const color = (backgroundColor.charAt(0) === '#') ? backgroundColor.substring(1, 7) : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? '#000' : '#fff';
}