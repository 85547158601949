<template>
    <div class="coverView">
        <div :style="styles" class="backgroundWrap bg-no-repeat bg-cover fixed w-full"></div>
        <div class="viewContent w-full" :style="{ marginTop: `${this.marginTop}vh` }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import colors from '@/utils/colors.js';

export default {
    props: {
        imageUrl: {
            type: String,
            'default': ''
        },
        gradient: {
            type: Boolean,
            'default': false
        },
        coverHeight: {
            type: Number,
            'default': 45
        },
        marginTop: {
            type: Number,
            'default': 0
        }
    },
    computed: {
        moduleColor() {
            return this.$route?.meta?.color;
        },
        styles() {
            const gradient = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 60%, ${ colors[this.moduleColor]} 90%, ${ colors[this.moduleColor] } 10%)`;

            const backgroundImage = this.gradient ? `${gradient}, url(${this.imageUrl})` : `url(${this.imageUrl})`;
            
            return `
                backgroundColor: ${!this.imageUrl.length ? colors[this.moduleColor] : 'none'};
                opacity: ${!this.imageUrl.length ? '0.9' : '1'};
                backgroundImage: ${backgroundImage};
                height: ${this.coverHeight}%;
            `
        }
    }
}
</script>

<style lang="scss">
    .coverView {
        transition: all 1s ease-out;
    }
    .backgroundWrap {
        background-position: center;
    }
    .viewContent {
        color: #323232;
        min-height: 1200px;
        position: absolute;

        > :first-child {
            border-radius: 25px 25px 0 0;
            background: white;
            margin-bottom: 200px;
            min-height: 1000px;
        }
    }
</style>