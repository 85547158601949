import engine from 'store/src/store-engine';
import defaultsPlugin from 'store/plugins/defaults';
import expirePlugin from 'store/plugins/expire';
import dumpPlugin from 'store/plugins/dump';
import updatePlugin from 'store/plugins/dump';

const plugins = [defaultsPlugin, expirePlugin, dumpPlugin, updatePlugin];

export const ls = engine.createStore([
  require('store/storages/localStorage')
], plugins);

export const ss = engine.createStore([
  require('store/storages/sessionStorage')
], plugins);
