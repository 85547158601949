<template>
    <div class="enter-pin flex flex-col h-full items-center p-10">
        <div v-if="!loginSuccessful"
                class="flex flex-col flex-1 justify-center items-center"
                :class="{ validating: pinValidation.validating }"
        >
            <div class="largeListItemHeader mb-10 pt-20 text-center error"
                    :style="{ opacity: wrongPin ? 1 : 0 }">
                    {{ $t('modals.wrongPassword') }}
            </div>

            <div class="content text-center mb-20">
                {{ activeOverlay.data.title }}
                <span v-if="activeOverlay.data.entityName" class="font-bold">{{ activeOverlay.data.entityName }}</span>
            </div>

            <div class="flex inline-flex mb-10 ml-12">
                <div v-for="(item, index) in 4"
                    :key="index"
                    class="w-14 h-14 border border-charcoal border-b-2 rounded-full"
                    :class="{ 'mr-3': index !== 4, 'bg-charcoal': pin.length >= item, 'bg-error': pinValidation.validPin === false }"
                    :style="{ opacity: 0.25 }"
                ></div>
                <fa-icon @click="pin.pop()" class="text-3xl self-center" :icon="['fal', 'backspace']"/>
            </div>

            <div class="flex flex-wrap mb-10 justify-center w-1/3">
                <div v-for="(number, index) in numbers"
                    :key="index"
                    class="w-20 h-20 shadow-lg rounded-full mb-3 text-5xl flex items-center justify-center text-white"
                    :class="{ 'mr-3': ![ 3, 6, 9, 0 ].includes(number) }"
                    :style="{ backgroundColor: colors[index] }"
                    @click="numberPressed(number)"
                >{{ number }}</div>
            </div>
        </div>

        <div v-else class="flex flex-col flex-1 justify-center items-center">
            <div class="largeListItemHeader mb-10 pt-20 text-center success">
                <fa-icon :icon="['fa', 'check']"/>
                {{ $t('global.completed') }}
            </div>
            
            <div class="content text-center mb-20">
                {{ activeOverlay.data.titleSuccess }}
                <span class="font-bold">{{ activeOverlay.data.entityName }}</span>
            </div>
        </div>

        <button-el
          text="global.close"
          icon="times"
          :shadow="true"
          class="back w-auto inline-block self-center"
          @click.native="close"
        />
    </div>
</template>

<script>

import Button from '@/components/generic/Button';
import { mapGetters } from 'vuex';

export default {
    components: {
        ButtonEl: Button
    },
    data() {
        return {
            numbers: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 0 ],
            colors: [ '#DF2121', '#2BB914', '#2134DF', '#DFB521', '#DC0BC7', '#E87B17', '#5E21DF', '#953600', '#1FA3B5', '#929292' ],
            pin: []
        }
    },
    computed: {
        ...mapGetters({
            activeProfile: 'profiles/activeProfile',
            activeOverlay: 'general/activeOverlay',
            pinValidation: 'general/pinValidation'
        }),
        data() {
            return this.activeOverlay.data;
        },
        wrongPin() {
            return this.pin.length === 4 && !this.pinValidation.validating && !this.pinValidation.validPin;
        },
        loginSuccessful() {
            return this.pinValidation.validPin;
        }
    },
    methods: {
        numberPressed(number) {
            if (this.pinValidation.validating) return;

            if (this.pin.length === 4 && this.wrongPin) {
                this.resetPin();
            }

            this.pin.push(number);

            if (this.pin.length === 4) {
                this.$store.dispatch('general/setPinValidation', { validating: true, pin: parseInt(this.pin.join(''))});
            }
        },
        resetPin() {
            this.$store.dispatch('general/setPinValidation', {});
            this.pin = [];
        },
        close() {
            this.$emit('closeOverlay');
            this.resetPin();
        }
    }
}
</script>


<style lang="scss">
    .enter-pin {
        .validating {
            opacity: 0.5;
        }
    }
</style>