import Vue from 'vue';
import Vuex from 'vuex';

import general from '@/store/modules/general';
import activities from '@/store/modules/activities';
import settings from '@/store/modules/settings';
import profiles from '@/store/modules/profiles';
import galleries from '@/store/modules/galleries';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general,
    settings,
    activities,
    profiles,
    galleries
  }
});
