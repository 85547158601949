import Vue from 'vue';
import VueRouter from 'vue-router';

import StartPage from '@/views/start-page/StartPage';
import Schedule from '@/views/schedule/Schedule';
import Booking from '@/views/booking/Booking';
import About from '@/views/about/About';
import Meals from '@/views/meals/Meals';
import Activities from '@/views/activities/Activities';
import Activity from '@/views/activities/Activity';
import Galleries from '@/views/gallery/Galleries';
import Gallery from '@/views/gallery/Gallery';
import Meetings from '@/views/meetings/Meetings';
import News from '@/views/news/News';
import Calendar from '@/views/calendar/Calendar';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Start Page',
    component: StartPage
  },
  {
    path: '/schedule',
    name: 'shiftPlan.moduleName',
    component: Schedule,
    meta: {
      color: 'schedule-light-default',
      icon: 'id-card',
      scrollUp: true
    }
  },
  {
    path: '/booking',
    name: 'booking.moduleName',
    component: Booking,
    meta: {
      color: 'booking-light-default',
      icon: 'key',
      scrollUp: true
    }
  },
  {
    path: '/about',
    name: 'ourHome.moduleName',
    component: About,
    meta: {
      color: 'about-light-default',
      icon: 'house-user',
      scrollUp: true
    }
  },
  {
    path: '/meals',
    name: 'meal.moduleName',
    component: Meals,
    meta: {
      color: 'meals-light-default',
      icon: 'utensils',
      scrollUp: true
    }
  },
  {
    path: '/activities',
    name: 'activities.moduleName',
    component: Activities,
    meta: {
      color: 'activities-light-default',
      icon: 'running',
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        },
        {
          name: 'filter',
          icon: 'icons',
          position: 'right',
          canClose: true
        }
      ]
    }
  },
  {
    path: '/activities/:id',
    name: 'activities.moduleName.one',
    component: Activity,
    meta: {
      hideInModuleList: true,
      color: 'activities-light-default',
      scrollUp: false
    }
  },
  {
    path: '/galleries',
    name: 'gallery.moduleName',
    component: Galleries,
    meta: {
      color: 'galleries-light-default',
      icon: 'photo-video',
      scrollUp: true,
      contentViews: [
        {
          name: 'list',
          icon: 'list',
          cardType: 'horizontal',
          position: 'center'
        },
        {
          name: 'grid',
          icon: 'th',
          cardType: 'vertical',
          position: 'center'
        },
        {
          name: 'filter',
          icon: 'icons',
          position: 'right',
          canClose: true
        }
      ]
    }
  },
  {
    path: '/galleries/:id',
    name: 'gallery.moduleName.one',
    component: Gallery,
    meta: {
      hideInModuleList: true,
      color: 'galleries-light-default',
      scrollUp: false
    }
  },
  {
    path: '/meetings',
    name: 'meeting.moduleName',
    component: Meetings,
    meta: {
      color: 'meetings-light-default',
      icon: 'comments-alt',
      scrollUp: true
    }
  },
  {
    path: '/news',
    name: 'news.moduleName',
    component: News,
    meta: {
      color: 'news-light-default',
      icon: 'comment-alt-lines',
      scrollUp: true
    }
  },
  {
    path: '/calendar',
    name: 'calendar.moduleName',
    component: Calendar,
    meta: {
      color: 'calendar-light-default',
      icon: 'calendar',
      scrollUp: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
