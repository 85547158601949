<template>
  <footer class="flex charcoal">
    <div class="date flex-none text-lg leading-none flex flex-col justify-center">
      <p class="dayOfWeek font-bold">{{dayOfWeek}}</p>
      <p class="date font-light">{{date}}</p>
    </div>

    <div class="actions flex-grow flex justify-center text-3xl">
      <div :class="{disabled: !canGoBackInHistory}" class="action flex justify-center items-center" @click="goBackInHistory">
        <fa-icon :icon="['fal', 'arrow-left']"/>
      </div>

      <div class="action flex justify-center items-center" @click="selectProfile">
        <fa-icon v-if="!activeProfile.displayImage" :icon="['fal', 'user-circle']"/>
        <img v-else class="profileImage" :src="activeProfile.displayImage" />
      </div>

      <router-link :disabled="onStartPage" :class="{ disabled: onStartPage }" to="/" class="action flex justify-center items-center">
        <fa-icon :icon="['fal', 'th']"/>
      </router-link>

      <div class="action flex justify-center items-center" @click="$emit('dimScreen')">
        <fa-icon :icon="['fal', 'lightbulb-slash']"/>
      </div>

      <div class="action flex justify-center items-center">
        <fa-icon :icon="['fal', 'universal-access']"/>
      </div>

      <div class="action flex justify-center items-center text-red-300" @click="feedbackAction">
        <fa-icon :icon="['fal', 'question-circle']"/>
      </div>
    </div>

    <video ref="video" v-show="false" />

    <div class="clock flex-none text-right flex flex-col justify-center">
      <flip-clock/>
    </div>

    <feedback-dialog v-if="showFeedbackDialog" @cancel="showFeedbackDialog=false" @startRecording="startFeedbackCapture" />
    <stop-feedback-dialog v-if="showStopFeedbackDialog" @cancel="showStopFeedbackDialog=false" @stopRecording="stopFeedbackCapture"/>
    <recording-notification v-if="isRecording"/>
  </footer>
</template>
<script>

import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { isEmpty, capitalize } from 'lodash';
import FlipClock from '@/components/generic/FlipClock';
import FeedbackDialog from '@/components/generic/FeedbackDialog';
import StopFeedbackDialog from '@/components/generic/StopFeedbackDialog';
import RecordingNotification from '@/components/generic/RecordingNotification';

export default {
  components: {
    FlipClock,
    FeedbackDialog,
    StopFeedbackDialog,
    RecordingNotification
  },
  data() {
   return {
      dayOfWeek: capitalize(format(new Date(), 'EEEE', { locale: da })),
      date: format(new Date(), 'd MMMM', { locale: da }),
      showFeedbackDialog: false,
      showStopFeedbackDialog: false,
      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],
      recordingNote: ''
    }
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile'
    }),
    onStartPage(){
      return this.$route.path === '/';
    },
    canGoBackInHistory(){
      return window.history.length && !this.onStartPage;
    }
  },
  methods: {
    goBackInHistory(){
      if(this.canGoBackInHistory) this.$router.back();
    },
    feedbackAction(){
      if(this.isRecording){
        this.showStopFeedbackDialog = true;
        return;
      }

      this.showFeedbackDialog = true;
    },
    async startFeedbackCapture() {
      this.showFeedbackDialog = false;
      if(!window.ipc) return;

      const inputSources = await window.desktopCapturer.getSources({ types: ['window'] });

      const sourceId = inputSources[0].id;
      const constraints = {
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: sourceId
          }
        }
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        await this.handleAudioStream(stream);
        // Create the Media Recorder
        const options = { mimeType: 'video/webm; codecs=vp9' };
        this.mediaRecorder = new MediaRecorder(stream, options);

        // Register Event Handlers
        this.mediaRecorder.ondataavailable = e => {
          this.recordedChunks.push(e.data);
        };

        this.mediaRecorder.onstop = this.handleStop;

        this.recordedChunks = [];
        this.recordingNote = '';
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch(e) {
        console.log(e);
      }
    },
    async handleAudioStream(stream) {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false});
        const audioTracks = audioStream.getAudioTracks();

        // mix audio tracks
        if(audioTracks.length > 0) {
          var mixAudioTrack = this.mixTracks(audioTracks);
          stream.addTrack(mixAudioTrack);
        }
      } catch(e){
        console.log(e);
      }
    },
    async stopFeedbackCapture(note) {
      this.showStopFeedbackDialog = false;
      this.recordingNote = note;
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
      this.isRecording = false;
    },
    async handleStop(e) {
      try {
        const blob = new Blob(this.recordedChunks, {
          type: 'video/webm; codecs=vp9'
        });

        this.recordedChunks = [];
        const buffer = Buffer.from(await blob.arrayBuffer());
        window.ipc.send('save-video', { buffer, note: this.recordingNote });
        this.recordingNote = '';
      } catch(e) {
        console.log(e);
      }
    },
    mixTracks(tracks) {
      const ac = new AudioContext();
      const dest = ac.createMediaStreamDestination();
      for(var i=0;i<tracks.length;i++) {
        const source = ac.createMediaStreamSource(new MediaStream([tracks[i]]));
        source.connect(dest);
      }
      return dest.stream.getTracks()[0];
    },
    selectProfile() {  
      this.$store.dispatch('general/setActiveOverlay', isEmpty(this.activeProfile) ? { name: 'profile-select', data: { action: 'setActiveProfile' } } : { name: 'profile-details' });
    }
  }
}
</script>
<style lang="scss">

  footer {
    height: 140px;
    padding: 20px 50px 30px;

    .date {
      letter-spacing: -0.03em;
      width: 200px;
    }

    .clock {
      width: 200px;
    }

    .action {
      width: 90px;
      height: 90px;
      &.disabled{
        opacity: 0.15;
      }
    }

    .profileImage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
</style>


098poiælk
