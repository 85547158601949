import gql from "graphql-tag";

export default gql`
query profiles ($filter: Filter!) {
    profiles (filter: $filter) {
        ... on Employee {
            type
            id
            displayImage
            showImage
            displayName,
            departments {
                id
            }
        }
        ... on Resident {
            type
            id
            displayImage
            showImage
            displayName
            departments {
                id
            }
        }
    }
}`