import Vue from 'vue';
import VueI18n from 'vue-i18n';
import da from '@/i18n/da.json';

const messages = {
  da
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'da',
  messages,
  silentTranslationWarn: true
});
