import {ls, ss} from '@/utils/storage';

const StoragePlugin = {
  install(Vue){
    Vue.prototype.$ls = ls;
    Vue.prototype.$ss = ss;
  }
};

export default StoragePlugin;
