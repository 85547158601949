var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasSelectedMediaItem
        ? _c("gallery-modal", {
            attrs: {
              selectedItemIndex: _vm.selectedMediaIndex,
              "media-type": _vm.mediaType,
              items: _vm.mediaItems
            },
            on: {
              close: function($event) {
                _vm.selectedMediaIndex = -1
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.galleries.length
        ? _c(
            "cover-view",
            {
              attrs: {
                "image-url": _vm.gallery.coverImage.source,
                gradient: false,
                "margin-top": 40
              }
            },
            [
              _c(
                "div",
                { staticClass: "galleryContent" },
                [
                  _c("media-icons", {
                    attrs: {
                      icons: _vm.mediaIcons,
                      "media-type": _vm.mediaType
                    },
                    on: {
                      "media-selected": function($event) {
                        _vm.mediaType = $event
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "largeListItemHeader p-20" }, [
                    _vm._v(_vm._s(_vm.gallery.title))
                  ]),
                  _vm._v(" "),
                  _vm.mediaType === "pictures"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-2 px-20 gap-3 object-cover"
                        },
                        _vm._l(_vm.gallery.pictures, function(image, index) {
                          return _c("img", {
                            key: index,
                            staticClass: "object-cover h-80 w-full",
                            attrs: { src: image.source },
                            on: {
                              click: function($event) {
                                _vm.selectedMediaIndex = index
                              }
                            }
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mediaType === "videos"
                    ? _c(
                        "div",
                        { staticClass: "w-full px-20" },
                        _vm._l(_vm.gallery.videos, function(video, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "video w-full mb-3 relative",
                              on: {
                                click: function($event) {
                                  _vm.selectedMediaIndex = index
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center"
                                },
                                [
                                  _c("fa-icon", {
                                    staticClass: "text-5xl",
                                    attrs: { icon: ["fal", "caret-right"] }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "w-full h-full object-cover",
                                attrs: { src: video.thumbnail }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }