var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "flex items-center justify-center",
      class: { onStartPage: _vm.onStartPage }
    },
    [
      _vm.onStartPage
        ? _c("img", { staticClass: "logo", attrs: { src: _vm.logoSource } })
        : _c(
            "p",
            { staticClass: "title text-3xl font-bold" },
            [
              _c("fa-icon", { attrs: { icon: _vm.$route.meta.icon } }),
              _vm._v("\n    " + _vm._s(_vm.$t(_vm.$route.name)) + "\n  ")
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }