var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-basic flex flex-col items-center justify-center relative p-4",
      class: ((_obj = {}), (_obj["bg-" + _vm.moduleColor] = _vm.active), _obj)
    },
    [
      _c(
        "span",
        {
          staticClass: "content bold text-center",
          style: { color: _vm.active ? "white" : _vm.moduleColorHex }
        },
        [_vm._v("\n        " + _vm._s(_vm.$t(_vm.title)) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.imageUrl
        ? _c("img", { staticClass: "mt-6", attrs: { src: _vm.imageUrl } })
        : _vm._e(),
      _vm._v(" "),
      _vm.active
        ? _c(
            "span",
            {
              staticClass:
                "active-icon rounded-full bg-success flex items-center justify-center border-2 border-white absolute"
            },
            [
              _c("fa-icon", {
                staticClass: "text-white text-3xl",
                attrs: { icon: ["fal", "check"] }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }