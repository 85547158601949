var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.itemType === "activity"
      ? _c(
          "div",
          { staticClass: "grid-view grid grid-cols-3 px-10 gap-14" },
          _vm._l(_vm.items, function(item, index) {
            return _c("card-advanced", {
              key: index,
              staticClass: "shadow-m",
              attrs: {
                cardType: _vm.activeContentView.cardType,
                title: item.title,
                weekday: _vm.getWeekday(item),
                meta: { icon: item.meta.icon, text: item.meta.text },
                isNow: _vm.isItemNow(item),
                isCancelled: item.cancelled,
                image: item.coverImage.source,
                gradient: true
              },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("item-selected", item)
                }
              }
            })
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "grid-view grid grid-cols-3 px-10 gap-14" },
          _vm._l(_vm.items, function(item, index) {
            return _c("card-advanced", {
              key: index,
              staticClass: "shadow-m",
              attrs: {
                cardType: _vm.activeContentView.cardType,
                title: item.title,
                meta: { icon: item.meta.icon, text: item.meta.text },
                image: item.coverImage.source,
                gradient: true
              },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("item-selected", item)
                }
              }
            })
          }),
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }