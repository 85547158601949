import gql from "graphql-tag";

export default gql`
query institution {
    institution {
        id
        name
        color
        locale
        logo {
            source
        }
        departments {
            id
            name
            color
        }
    }
}`;