var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c("div", { staticClass: "largeListItemHeader pb-4 pt-20 text-center" }, [
      _vm._v(_vm._s(_vm.$t("profileSelect.header")))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content mb-20 text-center" }, [
      _vm._v(_vm._s(_vm.$t("profileSelect.description")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grid grid-cols-3 gap-y-4 gap-x-4 pb-20" },
      _vm._l(_vm.shownProfiles, function(profile, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "profile h-full",
            class: {
              inline: _vm.size !== "large",
              stacked: _vm.size === "large"
            },
            on: {
              click: function($event) {
                return _vm.selectProfile(profile)
              }
            }
          },
          [
            _c("img", {
              staticClass: "profileImage rounded-full shadow-sm object-cover",
              class: _vm.size,
              attrs: { src: profile.displayImage }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "content font-light" }, [
              _vm._v(_vm._s(profile.displayName))
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "buttonWrapper fixed flex flex-col bottom-0 pb-10 pt-4 w-full bg-white shadow-scroll"
      },
      [
        _c("select-el", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSelectDepartment,
              expression: "showSelectDepartment"
            },
            {
              name: "clickoutside",
              rawName: "v-clickoutside",
              value: {
                excludeString: ".selectDepartment, .selectDepartment>*",
                handler: _vm.onClickoutside
              },
              expression:
                "{excludeString: '.selectDepartment, .selectDepartment>*', handler: onClickoutside}"
            }
          ],
          staticClass: "self-center align-center z-2",
          attrs: {
            options: _vm.departments,
            selectedOption: _vm.selectedDepartment,
            hideSelectedOption: true
          },
          on: { "option-selected": _vm.filterByDepartment }
        }),
        _vm._v(" "),
        _c("button-el", {
          staticClass: "selectDepartment w-auto inline-block mb-10 self-center",
          attrs: {
            text: _vm.selectedDepartment.name,
            icon: "house-user",
            "background-color": _vm.selectedDepartment.color
          },
          nativeOn: {
            click: function($event) {
              return _vm.toggleShowSelectDepartment($event)
            }
          }
        }),
        _vm._v(" "),
        _c("button-el", {
          staticClass: "back w-auto inline-block self-center",
          attrs: { text: "global.close", icon: "times", shadow: true },
          nativeOn: {
            click: function($event) {
              return _vm.close($event)
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "profileTypeToggle rounded-full shadow-lg w-14 h-14 absolute bottom-14 right-14 flex justify-center items-center",
            class: { employeeSelected: !_vm.showResidents },
            on: {
              click: function($event) {
                _vm.showResidents = !_vm.showResidents
              }
            }
          },
          [
            _c("fa-icon", {
              staticClass: "text-2xl",
              attrs: { icon: ["fal", "id-card-alt"] }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }