var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c("VueDatePicker", {
        attrs: {
          color: _vm.color,
          inline: _vm.showOnDefault,
          "no-header": _vm.hideHeader,
          "min-date": _vm.minDate,
          "allowed-dates": _vm.allowedDates,
          locale: _vm.locale
        },
        on: {
          onChange: function($event) {
            return _vm.$emit("changeDate", _vm.date)
          }
        },
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }